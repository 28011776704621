@charset "UTF-8";
@import "select2.css";
@import "jquery.bxslider.css";
@import "datepicker.css";

@import "../../common/helpers/variables"; //변수모음

@font-face {
    font-family: "Helvetica Neue";
    src: local("Helvetica Neue");
    unicode-range: U+0020;
}

@font-face {
    font-family: "Arial";
    src: local("Arial");
    unicode-range: U+0020;
}
/* font */
/* Nanum Gothic (Korean) http://hangeul.naver.com */
@font-face {
    font-family: "Nanum Gothic";
    font-style: normal;
    font-weight: 400;
    src: url(/css/fonts/NanumGothic-Regular.eot);
    src: url(/css/fonts/NanumGothic-Regular.eot?#iefix) format("embedded-opentype"), url(/css/fonts/NanumGothic-Regular.woff2) format("woff2"), url(/css/fonts/NanumGothic-Regular.woff) format("woff"), url(/css/fonts/NanumGothic-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "Nanum Gothic";
    font-style: normal;
    font-weight: 700;
    src: url(/css/fonts/NanumGothic-Bold.eot);
    src: url(/css/fonts/NanumGothic-Bold.eot?#iefix) format("embedded-opentype"), url(/css/fonts/NanumGothic-Bold.woff2) format("woff2"), url(/css/fonts/NanumGothic-Bold.woff) format("woff"), url(/css/fonts/NanumGothic-Bold.ttf) format("truetype");
}
@font-face {
    font-family: "Nanum Gothic";
    font-style: normal;
    font-weight: 800;
    src: url(/css/fonts/NanumGothic-ExtraBold.eot);
    src: url(/css/fonts/NanumGothic-ExtraBold.eot?#iefix) format("embedded-opentype"), url(/css/fonts/NanumGothic-ExtraBold.woff2) format("woff2"), url(/css/fonts/NanumGothic-ExtraBold.woff) format("woff"), url(/css/fonts/NanumGothic-ExtraBold.ttf) format("truetype");
}

/* noto-sans-kr-300 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
    src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("/css/fonts/noto-sans-kr-v13-latin_korean-300.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v13-latin_korean-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-regular - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-500 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    src: local("Noto Sans KR Medium"), local("NotoSansKR-Medium"), url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-700 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 700;
    src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"), url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* new lato-regular - latin */
@font-face {
    font-family: "Lato New";
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v23-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; //숫자 : U+0030-0039,영문 : U+0041-005A(대문자), U+0061-007A(소문자)
}

/* new lato-700 - latin */
@font-face {
    font-family: "Lato New";
    font-style: normal;
    font-weight: 700;
    src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v23-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
}

/* new lato-900 - latin */
@font-face {
    font-family: "Lato New";
    font-style: normal;
    font-weight: 900;
    src: local("Lato Black"), local("Lato-Black"), url("/css/fonts/lato-v23-latin-900.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v23-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
}

/* reset */
html,
body,
header,
section,
article,
aside,
footer,
nav,
hgroup,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
ul,
li,
figure,
fieldset {
    padding: 0;
    margin: 0;
}
[hidden],
area,
base,
basefont,
command,
datalist,
head,
input[type="hidden"],
link,
menu[type="context"],
meta,
noembed,
noframes,
param,
script,
source,
style,
track,
title {
    /* case-insensitive */
    display: none;
}
address,
article,
aside,
blockquote,
body,
center,
dd,
dir,
div,
dl,
dt,
figure,
figcaption,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
html,
legend,
listing,
menu,
nav,
ol,
p,
plaintext,
pre,
section,
summary,
ul,
xmp {
    display: block;
}
table {
    display: table;
    border-collapse: collapse;
    word-break: break-all;
    width: 100%;
}
caption {
    display: table-caption;
}
colgroup {
    display: table-column-group;
}
col {
    display: table-column;
}
thead {
    display: table-header-group;
}
tbody {
    display: table-row-group;
}
tfoot {
    display: table-footer-group;
}
tr {
    display: table-row;
}
td,
th {
    display: table-cell;
}
li {
    display: list-item;
}
ul,
li {
    list-style: none;
}
em,
address {
    font-style: normal;
}
ruby {
    display: ruby;
}
rt {
    display: ruby-text;
}
fieldset,
img,
img a {
    border: 0 none;
}
a {
    text-decoration: none;
    cursor: pointer;
}
a:link {
    color: #222;
}
a:visited {
    color: #222;
}
a:hover {
    text-decoration: underline;
}
a:hover,
a:focus,
a:active {
    color: #222;
}
html,
body {
    /* height: 100%; */
}
html {
    /* font-size: 62.5%; */
}
body {
    font-family: "Nanum Gothic", "나눔 고딕", Malgun Gothic, "맑은 고딕", sans-serif;
    color: #222;
    font-size: 0.875em;
    line-height: 1;
    background: #fff;
    min-width: 1280px;
    position: relative;
    overflow-y: scroll;
}
button {
    display: inline-block;
    border: 0 none;
    padding: 0;
    margin: 0;
    background: none;
    cursor: pointer;
    font-family: "Nanum Gothic", "나눔 고딕", Malgun Gothic, "맑은 고딕", sans-serif;
}
button[disabled] {
    cursor: default;
}



#footer{
    &.b-footer {
        border-top: 1px solid $color-gray-12;
        padding: 30px 0;
    }
}

.b-footer {
    border-top: 1px solid $color-gray-12;
    padding: 30px 0;
    font-family: $font-family;

    .eng,
    .num {
        display: inline-block;
        font-family: $font-family-lato;
    }

    &__inner {
        width: 1280px;
        margin: 0 auto;
    }
}

.c-footer-fnb {
    &__list {
        display: flex;
        align-items: center;
    }

    &__item {
        position: relative;
        padding: 0 12px;
        color: $color-gray-02;
        font-size: 0.928em;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 1px;
            height: 11px;
            background: $color-gray-09;
            transform: translateY(-50%);
        }

        &:first-child {
            padding-left: 0;

            &:before {
                content: none;
            }
        }

        &--point {
            color: #ff5888;
        }

        a {
            color: inherit;
            font-size: inherit;
        }
    }
}

.c-footer-info {
    margin-top: 20px;

    &__group {
        display: flex;
        justify-content: flex-start;

        &:not(:first-child) {
            margin-top: 8px;
        }
    }

    &__term,
    &__desc {
        margin: 0;
        color: $color-gray-04;
        font-size: 0.857em;
    }

    &__term {
        margin-right: 5px;
    }

    &__desc {
        margin-right: 5px;
    }

    &__link {
        position: relative;
        display: inline-block;
        padding-right: 9px;
        color: $color-gray-02;
        font-size: 12px;
        text-decoration: none;

        &:after {
            content: "";
            position: absolute;
            top: 1px;
            right: 0;
            @include sprite-retina($sp_main_2x_ico_arr_right);
        }

        &:hover {
            text-decoration: none;
        }
    }
}

.c-footer-copyright {
    margin-top: 22px;
    color: $color-gray-06;
    font-size: 10px;
    font-family: $font-family-lato;
}

.skip, .hide, .sr-only  {
    @include skip;
}

/* 탑배너 변경 */
#header-top-banner {
    &.type-bg-pink {
        padding: 15px 0;
        background: #ff5888;
        font-weight: bold;

        .header-banner-close {
            top: 11px;
            width: 24px;
            height: 24px;
            margin-left: 616px;
            background: none;

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 17px;
                height: 1px;
                background: $color-gray-14;
            }

            &:before {
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:after {
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
    }
}


/* 헤더 */
#header {
    &.b-header-util {
        border-top: none;
    }
}
.b-header-util {
    border-bottom: 1px solid $color-gray-12;
    font-family: $font-family;
}

.c-header-util {
    display: flex;
    align-items: center;
    width: 1280px;
    height: 120px;
    margin: 0 auto;

    &__logo {
        @include sprite-retina($sp_main_2x_logo_bizcon);
        margin-right: 29px;

        a {
            display: block;
            height: 100%;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &__search {
        position: relative;
        width: 463px;

        legend {
            @include skip;
        }

        &-label {
            position: absolute;
            top: 0;
            left: 0;
            color: transparent;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
        }

        input[type="text"],
        &-input {
            width: 100%;
            height: 54px;
            padding: 0 63px 0 26px;
            border-radius: 54px;
            border: 1px solid #ff5888;
            background: $color-gray-13;
            font-size: 1.1429em;
            box-sizing: border-box;
            overflow: hidden;
        }

        &-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 63px;
            height: 100%;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @include sprite-retina($sp_main_2x_ico_headersearch);
            }
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        margin-left: auto;

        &-item {
            flex-shrink: 0;
            position: relative;
            color: $color-gray-02;
            font-size: 1.071em;

            &:not(:first-child) {
                margin-left: 25px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: -12.5px;
                    width: 1px;
                    height: 10px;
                    background: $color-gray-11;
                    transform: translateY(-50%);
                }
            }

            a {
                color: inherit;
                font-size: inherit;
            }

            &--cart {
                @include sprite-retina($sp_main_2x_ico_cart);

                &:not(:first-child) {
                    margin-left: 20px;

                    &:before {
                        content: none;
                    }
                }

                a {
                    display: block;
                    height: 100%;
                    text-indent: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }

        &-count {
            position: absolute;
            top: -6px;
            right: -6px;
            min-width: 20px;
            height: 20px;
            padding: 0 6px;
            background: #ff5888;
            border-radius: 10px;
            color: $color-gray-14;
            font-size: 0.857em;
            font-weight: bold;
            font-family: $font-family-lato;
            text-align: center;
            line-height: 20px;
            box-sizing: border-box;
        }
    }
}

#gnb {
    &.b-header-gnb {
        background: $color-gray-14;
    }
}

.b-header-gnb {
    $gnb-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.03);
    $gnb-shadow-in: 0 4px 5px 0 rgba(0, 0, 0, 0.03) inset;
    $depth-shadow-out: 0px 5px 12px rgba(0, 0, 0, 0.06);

    position: relative;
    background: $color-gray-14;
    box-shadow: $gnb-shadow;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1280px;
        margin: 0 auto;
    }

    .c-header-gnb__depth {
        // box-shadow: $gnb-shadow-in, $depth-shadow-out;
        box-shadow: $depth-shadow-out;
    }

    .c-header-quick {
        box-shadow: $gnb-shadow-in, $gnb-shadow;
    }
}

.c-header-gnb {
    padding: 0 35px 0 91px;

    &__list {
        display: inline-flex;
        align-items: flex-start;
    }

    &__item {
        position: relative;
        background: $color-gray-14;
    }

    &__menu {
        display: block;
        height: 65px;
        padding: 0 25px;
        background: $color-gray-14;
        font-size: 1.214em;
        line-height: 65px;

        &:hover,
        &.active {
            color: #ff5888;
            font-weight: bold;
            text-decoration: none;

            .eng {
                letter-spacing: -0.2px;
            }
        }

        &.active {
            & + .c-header-gnb__depth {
                display: block;
            }
        }
    }

    &__depth {
        display: none;
        position: absolute;
        bottom: -1px;
        left: 0;
        min-width: 166px;
        padding: 24px 25px;
        background: $color-gray-14;
        // box-shadow: -5px 0px 5px 0 rgba(0, 0, 0, 0.02), 0 3px 5px 0 rgba(0, 0, 0, 0.03), 5px 0px 5px 0 rgba(0, 0, 0, 0.02);
        // box-shadow: 0 7px 5px 0 rgba(0, 0, 0, 0.03) inset;
        box-sizing: border-box;
        transform: translateY(100%);
        z-index: 1;
    }

    &__depthitem {
        color: #222;

        a {
            display: block;
            padding: 6px 0;
            color: inherit;
            white-space: nowrap;

            &:hover {
                color: #ff5888;
                text-decoration: none;
            }
        }
    }
}

.c-header-keyword {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 540px;
    padding-left: 85px;
    box-sizing: border-box;

    &__title {
        $tit-border-color: #c5e1f9; // rgba(11, 131, 230, 0.2)
        $tit-bg-color: #f5fafe; // rgba(11, 131, 230, 0.04)

        position: relative;
        flex-shrink: 0;
        margin-right: 16px;
        padding: 5px 6px;
        border-radius: 3px;
        border: solid 1px $tit-border-color;
        background-color: $tit-bg-color;
        color: $color-11st-blue;
        font-size: 0.857em;
        font-weight: normal;
        text-transform: capitalize;

        &:after {
            content: "";
            position: absolute;
            top: 8px;
            right: -4px;
            width: 6px;
            height: 6px;
            border: solid 1px $tit-border-color;
            border-top: 0;
            border-left: 0;
            background-color: $tit-bg-color;
            transform: rotate(-45deg);
        }
    }

    &__group {
        flex-shrink: 0;
        width: 460px;
        padding-bottom: 1px;
        overflow: hidden;
        white-space: nowrap;
    }

    &__tag {
        display: inline-block;
        color: $color-gray-02;
        font-size: 1.071em;
        vertical-align: middle;

        &:not(:first-child) {
            margin-left: 12px;
        }

        &.short {
            max-width: 100px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

.c-header-quick {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: $color-gray-14;
    // box-shadow: 0 7px 5px 0 rgba(0, 0, 0, 0.03) inset, 0 7px 5px 0 rgba(0, 0, 0, 0.03);
    transform: translateY(100%);
    z-index: 1;

    &__inner {
        position: relative;
        width: 1280px;
        margin: 0 auto;
    }

    &__open {
        position: absolute;
        top: -65px;
        left: 0;

        & ~ .c-header-quick__list {
            display: none;
        }

        &.active {
            & ~ .c-header-quick__list {
                display: flex;
            }
        }
    }

    &__opener {
        position: relative;
        width: 66px;
        height: 65px;
        background: #ff5888;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include sprite-retina($sp_main_2x_ico_category);
        }
    }

    &__list {
        display: flex;
        padding: 30px 0;
    }

    &__group {
        flex-grow: 1;
        flex-basis: 100%;
        padding: 0 10px 0 20px;

        &:not(:first-child) {
            border-left: 1px solid #e6e6e6;
        }

        &-menu {
            display: block;
            color: #222;
            font-size: 1em;
            font-weight: bold;

            &:hover {
                color: #ff5888;
                text-decoration: none;
            }
        }

        &-list {
            margin: 30px 0 -6px;
        }

        &-item {
            color: #222;
            font-size: 1em;

            a {
                display: block;
                padding: 6px 0;
                word-break: keep-all;

                &:hover {
                    color: #ff5888;
                    text-decoration: none;
                }
            }
        }
    }
}


@charset "UTF-8";

// 공통 - 비즈콘 색상 변수
$color-bizcon-pink: #ff5888;

// 공통 - 영문, 숫자 폰트 지정
.eng,
.num {
    display: inline-block;
    font-family: $font-family-lato;
}

// 공통 - 버튼
.c-button {
    @include c-button-set(1.071em, 0 2px, 150px, 48px, 205px, 4px);

    &--major {
        background: $color-bizcon-pink;
        color: $color-gray-14;
        font-weight: bold;
    }

    &--sub {
        background: $color-gray-14;
        border: 1px solid $color-bizcon-pink;
        color: $color-bizcon-pink;
        font-weight: bold;

        &[type="button"] {
            border: 1px solid $color-bizcon-pink;
        }

        &:link,
        &:visited,
        &:hover,
        &:focus,
        &:active {
            color: $color-bizcon-pink;
        }
    }

    &--grayline {
        background: $color-gray-14;
        color: $color-gray-02;
        border: 1px solid $color-gray-09;

        &[type="button"] {
            border: 1px solid $color-gray-09;
        }
    }

    &--func {
        background: $color-gray-03;
        color: $color-gray-14;
        font-weight: bold;

        &:disabled {
            background: $color-gray-09;
            color: $color-gray-14;
        }
    }
}

.c-button-wrap {
    margin-top: 80px;
    text-align: center;

    .c-button {
        margin: 0 5px;
    }
}

// 공통 -  인풋 체크박스
.c-checkbox {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &__text {
        position: relative;
        display: inline-block;
        height: 22px;
        padding-left: 30px;
        vertical-align: middle;
        line-height: 22px;
        font-size: 1.071em;
        color: $color-gray-03;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 22px;
            height: 22px;
            box-sizing: border-box;
            border: 1px solid $color-gray-09;
            background-color: $color-gray-14;
            // border-radius: 100%;
        }

        &:after {
            content: "";
            position: absolute;
            top: 6px;
            left: 5px;
            display: block;
            width: 10px;
            height: 6px;
            border: 1px solid $color-gray-09;
            border-width: 0 0 1px 1px;
            transform: rotate(-45deg);
        }
    }
    &__input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;

        &:checked {
            & + .c-checkbox__text {
                &:before {
                    border: 0;
                    background: $color-bizcon-pink;
                }

                &:after {
                    border-color: $color-gray-14;
                }
            }
        }

        &:disabled {
            & + .c-checkbox__text {
                color: $color-gray-09;

                &:before {
                    border: 0;
                    background-color: $color-gray-11;
                }

                &:after {
                    border-color: $color-gray-14;
                }
            }
        }

        // &:focus {
        //     & + .c-checkbox__text {
        //         &:before {
        //             box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
        //         }
        //     }
        // }

        &:focus-visible {
            & + .c-checkbox__text {
                &:before {
                    box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
                }
            }
        }
    }

    &--bold {
        .c-checkbox__text {
            color: $color-gray-02;
            font-weight: bold;
        }
    }
}

// 공통 -  인풋 라디오
.c-radio {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &__text {
        position: relative;
        display: inline-block;
        height: 22px;
        padding-left: 32px;
        vertical-align: middle;
        line-height: 22px;
        font-size: 1.071em;
        color: $color-gray-03;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            box-sizing: border-box;
            border: 1px solid $color-gray-08;
            background-color: $color-gray-14;
            background-image: radial-gradient(closest-side, $color-gray-08 47%, $color-gray-14 52%);
            border-radius: 100%;
        }
    }
    &__input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;

        &:checked {
            & + .c-radio__text {
                &:before {
                    border-color: $color-bizcon-pink;
                    background-image: radial-gradient(closest-side, $color-gray-14 47%, $color-bizcon-pink 52%);
                }
            }
        }

        &:disabled {
            & + .c-radio__text {
                color: $color-gray-09;

                &:before {
                    border-color: $color-gray-11;
                    background-image: radial-gradient(closest-side, $color-gray-14 47%, $color-gray-11 52%);
                }
            }
        }

        // &:focus {
        //     & + .c-radio__text {
        //         &:before {
        //             box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
        //         }
        //     }
        // }

        &:focus-visible {
            & + .c-radio__text {
                &:before {
                    box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
                }
            }
        }
    }
}

// 공통 - 인풋, 셀렉트 스타일
@mixin input-basic {
    display: block;
    width: 100%;
    height: 54px;
    padding: 0 20px;
    border: 1px solid $color-gray-10;
    color: $color-gray-02;
    font-size: 1.071em;
    line-height: 54px;
    box-sizing: border-box;
}

@mixin input-basic-readonly {
    border-color: $color-gray-11;
    background: $color-gray-13;
    color: $color-gray-06;
}

// 공통 -  인풋 텍스트, 셀렉트
.c-input {
    .c-button--sub {
        width: 150px;
    }

    &__label {
        display: inline-block;
        margin-bottom: 11px;
        color: $color-gray-03;
        font-size: 1.214em;
        cursor: pointer;

        & + * {
            clear: both;
        }
    }

    &__upper {
        & + .c-input__input {
            margin-top: 15px;
        }
    }

    // 인풋
    &__input {
        @include input-basic;

        &--readonly {
            @include input-basic-readonly;
        }
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"] {
        @include input-basic;

        &:read-only {
            @include input-basic-readonly;
        }

        &::placeholder {
            color: $color-gray-06;
        }
    }

    // 셀렉트
    select {
        width: 240px;
    }

    .select2-container {
        .select2-selection {
            &__rendered {
                padding: 0 20px;
                line-height: 54px;
                font-family: "Nanum Gothic", "나눔 고딕", Malgun Gothic, "맑은 고딕", sans-serif;
            }

            &__arrow {
                right: 20px;
                width: 16px;
                height: 54px;

                b {
                    width: 6px;
                    height: 6px;
                    margin-left: -2px;
                    margin-top: -3px;
                    border: 1px solid $color-gray-04;
                    border-width: 0 1px 1px 0;
                    transform: rotate(45deg) translateX(-50%);
                }
            }

            &--single {
                @include input-basic;
                padding: 0;
            }
        }

        &--open {
            .select2-selection--single {
                .select2-selection__arrow {
                    b {
                        margin-top: 0px;
                        border-color: $color-gray-04;
                        border-width: 1px 0 0 1px;
                    }
                }
            }
        }

        &--disabled {
            .select2-selection--single {
                border-color: $color-gray-11;
                background: $color-gray-13;
                color: $color-gray-06;

                .select2-selection__rendered {
                    color: $color-gray-06;
                }
            }
        }
    }

    &__align {
        display: flex;

        &--dash {
            .c-input__alignitem {
                &:not(.c-input__alignitem--button) {
                    position: relative;
                    margin-left: 18px;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: -12px;
                        width: 6px;
                        height: 1px;
                        background: $color-gray-06;
                    }

                    &:first-child {
                        &:before {
                            content: none;
                        }
                    }
                }
            }
        }
    }

    &__alignitem {
        flex-grow: 1;
        display: block;
        width: 100%;

        &:not(.c-input__alignitem--button) {
            margin-left: 10px;

            &:first-child {
                margin-left: 0;

                &:before {
                    content: none;
                }
            }
        }

        &--button {
            flex-shrink: 0;
            width: 94px;

            button {
                width: 94px;
                min-width: auto;
                height: 100%;
                border-radius: 0;
            }
        }
    }

    &__guide {
        margin-top: 8px;

        &-item {
            position: relative;
            padding-left: 6px;
            color: $color-gray-04;
            font-size: 1em;
            line-height: normal;

            &:not(:first-child) {
                margin-top: 8px;
            }

            &:before {
                content: "";
                position: absolute;
                top: 9px;
                left: 0;
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background: $color-gray-04;
            }
        }
    }
}

.select2-dropdown {
    border-color: $color-gray-10;
}

// 공통 - 체크박스, 라디오 그룹
.c-check-group {
    &__item {
        display: inline-block;
        vertical-align: middle;

        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    &--wide {
        .c-check-group__item {
            &:not(:first-child) {
                margin-left: 50px;
            }
        }
    }
}

// 공통 - 입력 양식 그룹
.c-form {
    &__item {
        position: relative;

        &:not(:first-child) {
            margin-top: 20px;
        }
    }

    &__info {
        position: absolute;
        top: 0;
        right: 0;
    }
}

// 공통 - 입력 양식 그룹 (리스트)
.c-form-list {
    &__group {
        margin: 0;
        padding: 30px 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }

        &:not(:first-child) {
            border-top: 1px solid $color-gray-11;
        }
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:not(:first-child) {
            margin-top: 15px;
        }

        &--aligntop {
            align-items: flex-start;

            .c-form-list__title {
                padding-top: 20px;
            }
        }
    }

    &__title {
        flex-basis: 150px;
        flex-shrink: 0;
        padding-right: 10px;
        box-sizing: border-box;
    }

    &__data {
        flex-grow: 1;
        width: calc(100% - 160px);
        margin: 0;
    }

    &__label {
        color: $color-gray-03;
        font-size: 1.214em;
        font-weight: normal;

        .required {
            display: inline-block;
            margin-left: 2px;
            color: $color-11st-red;
        }
    }

    label {
        cursor: pointer;
    }
}

// 공통 - 설명 레이어
.c-explain {
    position: relative;

    &__open {
        &.active {
            & + .c-explain__layer {
                display: block;
            }
        }
    }

    &__opener {
        padding-left: 22px;
        color: $color-gray-04;
        font-size: 1.071em;

        &:before {
            @include sprite-retina($sp_content_2x_ico_excla_18);
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }

    &__layer {
        position: absolute;
        right: 0;
        bottom: -2px;
        display: none;
        width: 460px;
        border-radius: 4px;
        background: $color-gray-14;
        box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.36);
        transform: translateY(100%);
    }

    &__title {
        padding: 19px 55px 19px 15px;
        border-bottom: 1px solid $color-gray-11;
        color: $color-gray-02;
        font-size: 1.143em;
        font-weight: 800;
    }

    &__cont {
        padding: 20px 15px 15px;

        .num_list {
            margin: 0;
            padding: 0;

            li {
                position: relative;
                padding-left: 15px;
                color: $color-gray-02;
                font-size: 1.071em;
                line-height: normal;

                &:not(:first-child) {
                    margin-top: 11px;
                }

                .num_sublist {
                    margin: 9px 0 0;
                    padding: 0;

                    li {
                        position: relative;
                        padding-left: 15px;
                        color: $color-gray-04;
                        font-size: 0.933em;
                        line-height: normal;

                        &:not(:first-child) {
                            margin-top: 2px;
                        }

                        .num {
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }

                .num {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    &__closer {
        position: absolute;
        top: 15px;
        right: 16px;
        @include ico-cancel-retina(24px, 2px, $color-gray-02, 0);
    }
}

// 공통 - 타이틀
.c-p-title {
    position: relative;
    margin-bottom: 40px;

    .c-steps {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &__title {
        color: $color-gray-02;
        font-size: 1.857em;
        font-weight: 800;
        line-height: 1.15;
    }

    &__desc {
        margin-top: 6px;
        color: $color-gray-03;
        font-size: 14px;
        line-height: normal;
    }

    &--center {
        text-align: center;
    }
}

.c-p-subtitle {
    margin-bottom: 30px;

    &__title {
        color: $color-gray-02;
        font-size: 1.429em;
        font-weight: 800;
        line-height: 1.7;
    }

    &__desc {
        margin-top: 15px;
        color: $color-gray-02;
        font-size: 1em;
    }

    &--underline {
        margin-bottom: 30px;
        padding-bottom: 26px;
        border-bottom: 1px solid $color-gray-07;
    }
}

// 공통 - 스텝
.c-steps {
    // position: absolute;
    // right: 0;
    // top: -6px;
    margin: 0;

    &__item {
        position: relative;
        float: left;
        height: 44px;
        margin: 0 0 0 -1px;
        padding: 0 16px;
        border: 1px solid $color-gray-11;
        box-sizing: border-box;
        line-height: 42px;
        font-size: 1em;
        color: $color-gray-03;
        background: $color-gray-14;
        // font-family: $font-family;

        &:first-child {
            padding-left: 20px;
            border-top-left-radius: 24px;
            border-bottom-left-radius: 24px;
        }
        &:last-child {
            padding-right: 20px;
            border-top-right-radius: 24px;
            border-bottom-right-radius: 24px;
        }

        &--active {
            margin-right: -1px;
            background: $color-gray-03;
            border-color: $color-gray-03;
            color: $color-gray-14;
            font-weight: bold;
            z-index: 10;
        }
    }
    .num {
        font-family: $font-family-lato;
        font-weight: bold;
    }
}

// 공통 - 테이블
.c-table {
    th,
    td {
        height: 48px;
        padding: 15px 10px;
        border: 1px solid $color-gray-09;
        box-sizing: border-box;
        font-weight: normal;

        .bold,
        &.bold {
            font-weight: bold;
        }
    }

    th {
        background: $color-gray-11;
    }

    td {
        vertical-align: top;
    }

    &--center {
        text-align: center;

        td {
            vertical-align: middle;
        }
    }
}

// 공통 - 약관동의
.c-agree {
    // margin: 26px 0 0 0;

    &__check-all {
        margin: 0 0 15px 0;
        font-size: 1.071em;
        color: $color-gray-02;

        // .c_checkbox {
        //     > span:nth-of-type(1) {
        //         color: $color-gray-02;
        //         font-weight: bold;
        //     }
        // }
    }

    &__check-list {
        // padding: 1px 0 0 0;
        border: 1px solid $color-gray-10;
    }

    &__check-item {
        position: relative;
        // margin: -1px 0 0 0;
        padding: 19px 20px 20px 20px;

        &:not(:first-child) {
            border-top: 1px solid $color-gray-10;
        }
    }

    &__view {
        position: absolute;
        right: 29px;
        top: 12px;
        padding: 10px 15px 10px 10px;

        &::before {
            @include ico-angle("right", 6px, 1px, $color-gray-02, "static");
            position: absolute;
            right: 0;
            top: 50%;
            margin: -3px 0 0 0;
        }

        // &:focus,
        // &:hover {
        //     text-decoration: none;
        // }
    }

    &__desc {
        margin-top: 10px;
    }

    &__desc-item {
        position: relative;
        padding-left: 6px;
        color: $color-gray-04;
        font-size: 1em;

        &:before {
            content: "";
            position: absolute;
            top: 6px;
            left: 0;
            width: 2px;
            height: 2px;
            background: $color-gray-04;
            border-radius: 50%;
        }
    }
}

// 공통 - 정보 리스트
.c-info-list {
    margin: 0;
    color: $color-gray-03;

    &__row {
        display: flex;

        &:not(:first-child) {
            margin-top: 15px;
        }
    }

    &__title {
        flex-basis: 160px;
        flex-shrink: 0;
        // flex-grow: 1;
        padding-right: 20px;
        font-weight: bold;
        box-sizing: border-box;
    }

    &__data {
        flex-grow: 1;
        margin: 0;
    }
}

// 공통 - 정보 리스트 그룹
.c-info-group {
    &__item {
        &:not(:first-child) {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid $color-gray-12;
        }
    }
}

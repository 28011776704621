@charset "UTF-8";
@import "common/partial/component/content_common";

// 회원
.s-user {
    width: 1280px;
    margin: 0 auto;
    font-family: $font-family;
}

.l-user {
    padding: 48px 0 100px;

    [class*="b-"] {
        width: 640px;
        margin-left: auto;
        margin-right: auto;
    }
}

// 회원 - 개인 회원 서비스 종료 및 기업(사업자)회원 전환 안내 - 임시페이지
.l-user--service-end {
    .temp-cont1 {
        width: 650px;
        height: 740px;
        margin: 0 auto;
        background: url("/img/bizcon/content/priv_mem_service_end_2x.png") 50% -50px / 1600px auto no-repeat;

        &-btn {
            text-align: center;
        }
    }

    .temp-cont2 {
        width: 650px;
        height: 332px;
        margin: 0 auto;
        background: url("/img/bizcon/content/priv_mem_service_end_2x.png") 50% -840px / 1600px auto no-repeat;

        &-btn {
            text-align: center;

            button {
                margin: 0 5px;
            }
        }
    }

    .event-banner {
        width: 640px;
        min-height: 172px;
        margin: 80px auto 0;
        background: #f7f2d2;

        a {
            display: block;

            img {
                display: block;
                width: 100%;
            }
        }
    }
}

// 회원 - 회원가입 안내
.l-user--info {
    width: 640px;
    margin: 0 auto;
    padding: 80px 0 82px;
    text-align: center;

    .joinin-info {
        & > div {
            display: flex;
            align-items: center;
            justify-content: center;

            &:not(:first-child) {
                margin-top: 4px;
            }
        }

        dt {
            position: relative;
            margin-right: 12px;
            padding-left: 10px;
            color: $color-gray-02;
            font-size: 1.142em;
            font-weight: bold;

            &:before {
                content: "";
                position: absolute;
                top: 6px;
                left: 0;
                width: 4px;
                height: 4px;
                background: $color-gray-08;
                border-radius: 50%;
            }
        }

        dd {
            margin: 0;
            color: $color-gray-03;
            font-size: 1em;
        }
    }

    .joinin-button {
        margin-top: 60px;

        button {
            @include c-button-set(1.286em, 0 2px, 150px, 56px, 328px, 4px);
        }
    }

    .event-banner {
        width: 640px;
        min-height: 172px;
        margin: 80px auto 0;
        background: #f7f2d2;

        a {
            display: block;

            img {
                display: block;
                width: 100%;
            }
        }
    }
}

// 회원 - 회원약관동의
.l-user--agree {
    [class*="b-agree"] {
        width: 640px;
        margin-left: auto;
        margin-right: auto;
    }

    .b-agree-certi {
        margin-top: 60px;
    }
}

.b-agree-certi {
    &__title {
        margin-bottom: 20px;

        .c-p-subtitle {
            margin-bottom: 0;
        }
    }

    .regist-number-input {
        .c-input__alignitem {
            width: 170px;
        }

        .c-input__alignitem--button {
            width: 94px;
        }
    }
}


.agree-terms {
    font-size: 0.929em;

    &__require {
        margin-bottom: 20px;
        font-weight: bold;

        .emph {
            color: $color-11st-red;
        }
    }

    &__chapter,
    &__article {
        font-size: inherit;
        font-weight: bold;
    }

    &__check {
        margin-bottom: 16px;
    }
}

// 회원 - 회원정보입력
.l-user--join-form {
    [class*="b-join"] {
        width: 640px;
        margin-left: auto;
        margin-right: auto;
    }

    .user-header {
        margin-bottom: 67px;

        .c-p-title {
            margin-bottom: 0;
        }
    }
}

.b-join-form {
    &__title {
        &:not(:first-child) {
            margin-top: 57px;
        }
    }

    .busi-sort {
        padding: 9px 0;
    }

    .busi-type-select {
        .c-input__alignitem,
        select {
            width: 240px;
        }
    }

    .work-part {
        .c-input__alignitem {
            width: 240px;
        }
    }
}

.user-receive {
    &__item {
        &:not(:first-child) {
            margin-left: 12px;
        }
    }

    &__result {
        display: inline-block;
        margin-left: 4px;
        color: $color-bizcon-pink;
    }
}

// 회원 - 전환가입 완료
.l-user--join-complete {
    .user-header {
        margin-bottom: 67px;

        .c-p-title {
            margin-bottom: 0;
        }
    }

    .event-banner {
        width: 640px;
        min-height: 172px;
        margin: 80px auto 0;
        background: #f7f2d2;

        a {
            display: block;

            img {
                display: block;
                width: 100%;
            }
        }
    }
}

.b-join-complete {
    &__msg {
        text-align: center;

        &-major {
            color: $color-gray-02;
            font-size: 2.143em;
            font-weight: bold;
        }

        &-sub {
            margin-top: 16px;
            color: $color-gray-04;
            font-size: 1.214em;
            line-height: normal;
        }

        &-id {
            color: $color-bizcon-pink;
        }
    }
}

.c-bizcon-card {
    $size-basic: 275px;
    $size-list: 400px;

    position: relative;
    display: flex;
    flex-direction: column;
    flex-basis: $size-basic;
    width: $size-basic;

    &_img, // 개발 화면에 c-bizcon-card_img로 추가되어있어서 이에 대응하기 위한 선택자 추가
    &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        height: $size-basic;
        margin: 0 0 16px;
        // border: solid 1px rgba(0, 0, 0, 0.1);
        background: $color-gray-14;
        box-sizing: border-box;

        &:hover {
            & ~ .c-bizcon-card__functions {
                display: flex;
                width: 100%;
                height: $size-basic;
            }
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__info {
        position: relative;
    }

    &__ranking {
        position: absolute;
        top: 0;
        left: 0;
        width: 37px;
        height: 37px;
        background-color: #ff5888;
        color: $color-gray-14;
        font-size: 1.143em;
        font-family: $font-family-lato;
        text-align: center;
        line-height: 37px;
    }

    &__brand {
        display: block;
        width: 100%;
        margin-bottom: 6px;
        color: $color-gray-04;
        font-size: 1em;
        line-height: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__name {
        display: block;
        width: 100%;
        max-height: 48px;
        margin-bottom: 10px;
        color: $color-gray-02;
        font-size: 1.071em;
        line-height: 1.6em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        a {
            display: block;
            color: inherit;
            font-size: inherit;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__price {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        color: #222;
        font-size: 1em;
        font-family: $font-family-lato;
    }

    &__rate {
        margin-right: 6px;
        color: #ff5888;
        font-size: inherit;
    }

    &__discount {
        margin-right: 4px;
        color: #222;
        font-size: inherit;
    }

    &__before {
        color: $color-gray-07;
        font-size: inherit;

        .delete {
            text-decoration: line-through;
        }
    }

    &__num {
        position: relative;
        top: -2px;
        color: inherit;
        font-size: 1.571em;
        font-weight: bold;
        vertical-align: middle;

        &--small {
            position: static;
            font-size: 1em;
            font-weight: normal;
        }
    }

    &__unit {
        color: inherit;
        vertical-align: middle;
    }

    &__desc {
        display: block;
        min-height: 42px;
        margin-top: 12px;
        color: $color-gray-04;
        font-size: 0.929em;
        line-height: 1.6em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__tags {
        position: absolute;
        bottom: 3px;
        right: 0;
    }

    &__tag {
        display: inline-block;
        min-width: 36px;
        padding: 2px 5px;
        font-size: 0.785em;
        border: 1px solid;
        border-radius: 2px;
        box-sizing: border-box;
        text-align: center;
        font-weight: bold;
        font-family: $font-family-lato;

        &--best {
            border-color: $color-11st-blue;
            color: $color-11st-blue;
        }

        &--sale {
            border-color: #ff5888;
            color: #ff5888;
        }
    }

    &__functions {
        position: absolute;
        top: 0;
        left: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 0px;
        height: 0px;
        background: rgba(0, 0, 0, 0.4);
        overflow: hidden;

        &:hover,
        &.active {
            display: flex;
            width: 100%;
            height: $size-basic;
        }
    }

    &__func-button {
        //font-size, padding, min-width, height, width, border-radius
        @include c-button-set(1.071em, 0 2px, 133px, 41px, 133px, 3px);
        border: 1px solid $color-gray-14;
        background: none;
        color: $color-gray-14;
        font-weight: bold;

        & + & {
            margin-top: 12px;
        }

        &:hover {
            background: $color-gray-14;
            color: #ff5888;
        }
    }

    &:hover {
        .c-bizcon-card__name {
            text-decoration: underline;
        }
    }

    &--list {
        flex-direction: row;
        flex-basis: $size-list;
        width: $size-list;

        .c-bizcon-card_img, // 개발 화면에 c-bizcon-card_img로 추가되어있어서 이에 대응하기 위한 선택자 추가
        .c-bizcon-card__img {
            width: 160px;
            height: 160px;
            margin: 0 16px 0 0;
        }

        .c-bizcon-card__info {
            width: 224px;
        }

        .c-bizcon-card__name {
            min-height: 48px;
        }
    }

    &--list-box {
        flex-direction: row;
        flex-basis: $size-list;
        width: $size-list;
        padding: 1px;
        border: 1px solid $color-gray-11;
        box-sizing: border-box;

        .c-bizcon-card_img, // 개발 화면에 c-bizcon-card_img로 추가되어있어서 이에 대응하기 위한 선택자 추가
        .c-bizcon-card__img {
            width: 160px;
            height: 160px;
            margin: 0;
            border: none;
        }

        .c-bizcon-card__info {
            position: static;
            width: 238px;
            padding: 20px;
            box-sizing: border-box;
        }

        .c-bizcon-card__name {
            min-height: 48px;
        }
    }
}

/* blind */
.no-view {
    position: absolute;
    left: -9999em;
}

/* skip */
#page-top ul li a,
#page-bottom a {
    display: block;
    width: 100%;
    padding: 20px;
    text-align: center;
    color: #fff;
    background: #ff5757;
    position: absolute;
    top: 0;
    left: -9999em;
}
#page-bottom a {
    top: auto;
    bottom: 0;
}
#page-top ul li a:focus,
#page-bottom a:focus {
    position: fixed;
    left: 0;
    z-index: 30;
}

/* align */
.al-left {
    text-align: left;
}
.al-right {
    text-align: right;
}
.al-center {
    text-align: center;
}

/* float */
.fl-left {
    float: left;
}
.fl-right {
    float: right;
}
.fl-none {
    float: none;
}
.fl-clear {
    *zoom: 1;
}
.fl-clear:after {
    content: "";
    display: block;
    clear: both;
}

/* text */
.txt-red {
    color: #ff5757 !important;
    font-weight: 400;
}
.txt-grey {
    color: #959595;
}
.txt-regular {
    font-weight: 400;
}
.txt-bold {
    font-weight: 700;
}
.txt-ex-bold {
    font-weight: 800;
}
.txt-label,
.txt-label-bul {
    display: inline-block;
    height: 40px;
    width: 100px;
    line-height: 40px;
    padding-left: 8px;
    vertical-align: middle;
}
.txt-label-bul {
    background: url(/img/bizcon/bullet-black.gif) no-repeat 0 center;
}
.txt-input {
    display: inline-block;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    color: #ff5757;
    vertical-align: middle;
}
.txt-form {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    margin: 0 5px;
    vertical-align: middle;
}
.txt-title {
    font-size: 2em;
    letter-spacing: -1px;
}
.txt-title.type02 {
    font-size: 2.2857em;
}
.txt-title .txt-red {
    margin-left: 8px;
}
.txt-info em {
    font-weight: 700;
    font-size: 1.2857em;
}
.txt-del {
    text-decoration: line-through;
}
.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.txt-underline {
    border-bottom: 1px solid #222;
}
.txt-desc {
    padding: 12px 0 4px 0;
    font-size: 16px;
    line-height: 23px;
    color: $color-gray-04;
}

/* margin */
.mt0 {
    margin-top: 0 !important;
}
.mt5 {
    margin-top: 5px !important;
}
.mt8 {
    margin-top: 8px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt15 {
    margin-top: 15px !important;
}
.mt20 {
    margin-top: 20px !important;
}
.mt25 {
    margin-top: 25px !important;
}
.mt30 {
    margin-top: 30px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt42 {
    margin-top: 42px !important;
}
.mt45 {
    margin-top: 45px !important;
}
.mt50 {
    margin-top: 50px !important;
}
.mt55 {
    margin-top: 55px !important;
}
.mt60 {
    margin-top: 60px !important;
}
.mt80 {
    margin-top: 80px !important;
}
.mr0 {
    margin-right: 0 !important;
}
.mr5 {
    margin-right: 5px !important;
}
.mr20 {
    margin-right: 20px !important;
}
.mb10 {
    margin-bottom: 10px !important;
} /* 2018-04-13 */
.mb20 {
    margin-bottom: 20px !important;
}
.mb30 {
    margin-bottom: 30px !important;
}
.ml0 {
    margin-left: 0 !important;
}
.ml5 {
    margin-left: 5px !important;
}
.ml10 {
    margin-left: 10px !important;
}
.ml20 {
    margin-left: 20px !important;
}
.ml30 {
    margin-left: 30px !important;
}
.ml50 {
    margin-left: 50px !important;
}
.ml70 {
    margin-left: 70px !important;
}
.ml78 {
    margin-left: 78px !important;
}

/* padding */
.pl0 {
    padding-left: 0 !important;
}
.pl20 {
    padding-left: 20px !important;
}
.pr0 {
    padding-right: 0 !important;
}

/* width */
.w140 {
    width: 140px;
}
.w150 {
    width: 150px;
}
.w180 {
    width: 180px;
}
.w200 {
    width: 200px;
}
.w250 {
    width: 250px;
}
.w400 {
    width: 400px;
}
.w800 {
    width: 800px;
}

/* button */
button[class*="btn-"],
a[class*="btn-"] {
    display: inline-block;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-size: 1em;
    width: 150px;
    text-align: center;
    font-weight: 700;
    vertical-align: middle;
    border-radius: 3px;
    font-family: "Nanum Gothic", "나눔 고딕", Malgun Gothic, "맑은 고딕", sans-serif;

    &.w110 {
        width: 110px;
        min-width: auto;
    }
    &.w130 {
        width: 130px;
        min-width: auto;
    }
}
button[class*="btn-"] {
    height: 40px;
}
a[class*="btn-"] {
    height: 38px;
    line-height: 38px;
}
a[class*="btn-"]:hover {
    text-decoration: none;
}
.value-clear {
    text-indent: -9999em;
    width: 20px;
    height: 20px;
    background: url(/img/bizcon/icon-clear.png) no-repeat 0 0;
}
button.btn-login {
    width: 158px;
    height: 88px;
    color: #fff;
    border: 1px solid #ff5757;
    background: #ff5757;
}
.btn-line-red,
a.btn-line-red {
    color: #ff5757;
    border: 1px solid #ff5757;
    background: #fff;
}
.btn-line-pink,
a.btn-line-pink {
    color: #ff5888;
    border: 1px solid #ff5888;
    background: #fff;
}
.btn-line-grey,
a.btn-line-grey {
    color: #222;
    border: 1px solid #ccc;
    background: #fff;
}
button.btn-line-white,
a.btn-line-white {
    color: #fff;
    width: 130px;
    border: 1px solid #fff;
}
.btn-line-black,
a.btn-line-black {
    color: #222;
    border: 1px solid #222;
    background: #fff;
}
.btn-red,
a.btn-red {
    color: #fff;
    border: 1px solid #ff5757;
    background: #ff5757;
}
.btn-pink,
a.btn-pink {
    color: #fff;
    border: 1px solid #ff5888;
    background: #ff5888;
}
.btn-grey,
a.btn-grey {
    color: #fff;
    border: 1px solid #6b6b6b;
    background: #6b6b6b;
}
button.btn-white,
a.btn-white {
    color: #ff5757;
    width: 130px;
    background: #fff;
    border: 1px solid #fff;
}
button.btn-red.type02,
a.btn-red.type02 {
    width: 225px;
    height: 64px;
    line-height: 64px;
}
button.btn-red.type03,
a.btn-red.type03 {
    width: 225px;
    height: 48px;
    padding-top: 16px;
    line-height: 1.2;
}
button.btn-underline,
a.btn-underline {
    height: auto;
    width: auto;
    font-weight: 400;
    border-radius: 0;
    padding-bottom: 2px;
    line-height: 1.4;
    border-bottom: 1px solid #222;
}
button[class*="btn-"].short,
a[class*="btn-"].short {
    width: 90px;
}
button[class*="btn-"].short02,
a[class*="btn-"].short02,
button[class*="btn-"].short03,
a[class*="btn-"].short03 {
    width: 70px;
    font-weight: 400;
}
button[class*="btn-"].short02 {
    height: 30px;
}
a[class*="btn-"].short02 {
    height: 28px;
    line-height: 28px;
}
button[class*="btn-"].short03 {
    height: 22px;
    font-size: 0.9em;
}
a[class*="btn-"].short03 {
    height: 20px;
    line-height: 20px;
    font-size: 0.9em;
}

button[class*="btn-"].short04 {
    width: auto;
    height: 30px;
    padding: 0 12px 0 12px;
    line-height: 30px;
    font-size: 13px;
    color: #222;
    font-weight: normal;
}
button[class*="btn-"].middle,
a[class*="btn-"].middle {
    width: 205px;
}
button[class*="btn-"].middle02,
a[class*="btn-"].middle02 {
    width: 180px;
}
button[class*="btn-"].auto,
a[class*="btn-"].auto {
    width: auto;
    padding: 0 25px;
}
button[class*="btn-"].auto.type02 {
    height: 34px;
}
a[class*="btn-"].auto.type02 {
    height: 32px;
    line-height: 32px;
}
button[class*="btn-"][disabled] {
    opacity: 0.5;
    filter: Alpha(opacity=50);
    cursor: default;
}

/* form elements */
input,
textarea,
pre {
    margin: 0;
    font-family: "Nanum Gothic", "나눔 고딕", Malgun Gothic, "맑은 고딕", sans-serif;
    border-radius: 0;
}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
    height: 38px;
    line-height: 38px;
    padding: 0 10px;
    border: 1px solid #ccc;
    vertical-align: middle;
}
input::-ms-clear {
    display: none;
}
textarea {
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 10px;
    resize: none;
}
select {
    padding: 0;
    height: 40px;
    line-height: 40px;
    border: 1px solid #ccc;
}
input[readonly],
textarea[readonly] {
    border: 1px solid #ccc;
    background: #fafafa;
}

/* 파일 업로드 */
.file-box {
    display: inline-block;
}
.file-box input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
.file-box label {
    display: inline-block;
    width: 100px;
    line-height: 38px;
    margin-left: 10px;
    font-weight: 700;
    color: #222;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}
.file-box input.file-name {
    display: inline-block;
    height: 38px;
    line-height: 38px;
    padding: 0 10px;
    border: 1px solid #ccc;
    vertical-align: middle;
    background: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/* 체크박스, 라디오 */
.check-box,
.radio {
    height: 25px;
    display: inline-block;
    position: relative;
}
.check-box input[type="checkbox"],
.radio input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 25px;
    height: 100%;
    opacity: 0;
    filter: Alpha(opacity=0);
    z-index: 5;
    cursor: pointer;
}
.check-box label,
.radio label {
    display: inline-block;
    height: 100%;
    line-height: 25px;
    padding-left: 35px;
}
.check-box.type02 label {
    padding-left: 25px;
}
.check-box label {
    background: url(/img/bizcon/bg-check-off.gif) no-repeat 0 0;
}
.check-box input[type="checkbox"]:checked + label {
    background: url(/img/bizcon/bg-check-on.gif) no-repeat 0 0;
}
.radio label {
    background: url(/img/bizcon/bg-radio-off.png) no-repeat 0 0;
}
.radio input[type="radio"]:checked + label {
    background: url(/img/bizcon/bg-radio-on.png) no-repeat 0 0;
}

.check-box.type03 label {
    height: 22px;
    line-height: 21px;
    padding-left: 31px;
    font-size: 14px;
    background: url(/img/bizcon/bg-check-off.png) no-repeat 0 0;
    background-size: 22px 22px;
}
.check-box.type03 input[type="checkbox"]:checked + label {
    /* background: url(/img/bizcon/bg-check-on.png) no-repeat 0 0; */
    background: url(/img/bizcon/bg-checkpink-on.png) no-repeat 0 0;
    background-size: 22px 22px;
}

.check-box.type03 input[type="checkbox"]:disabled + label {
    background: url(/img/bizcon/bg-check-disabled.png) no-repeat 0 0;
    background-size: 22px 22px;
}

.check-box.type04 label {
    height: 22px;
    line-height: 21px;
    padding-left: 31px;
    font-size: 14px;
    background: url(/img/bizcon/bg-check-r-off.png) no-repeat 0 0;
    background-size: 22px 22px;
}
.check-box.type04 input[type="checkbox"]:checked + label {
    background: url(/img/bizcon/bg-check-r-on.png) no-repeat 0 0;
    background-size: 22px 22px;
}

/* 테이블 */
.tb-comment {
    text-align: right;
    margin-top: -24px;
    margin-bottom: 10px;
}
.tb-comment.type02 {
    margin: 8px 0 10px;
}
.tb-default {
    background: #fff;
}
.tb-default caption span {
    display: block;
    font-size: 1.2857em;
    padding-bottom: 10px;
}
.tb-default tr:first-child th,
.tb-default tr:first-child td {
    border-top: 1px solid #b2b2b2;
}
.tb-default.type02 tr:first-child th,
.tb-default.type02 tr:first-child td {
    border-top: 0 none;
}
.tb-default th,
.tb-default td {
    /* height: 50px; */
    text-align: left;
    border-bottom: 1px solid #e6e6e6;
}
.tb-default th {
    background: #f7f7f7;
    padding: 20px 20px 20px 30px;
}
.tb-default th.bg-red {
    background: #ff5757;
    color: #fff;
}
.tb-default td {
    padding: 10px 20px;
}
.tb-default .tb-txt {
    padding: 20px 0;
}
.tb-bold-line thead th {
    border-top: 2px solid #494e55;
    border-bottom: 2px solid #494e55;
}
.tb-bold-line th,
.tb-bold-line td {
    padding: 12px;
}
.tb-bold-line.type02 th,
.tb-bold-line.type02 td,
.tb-bold-line.type03 th {
    padding: 10px 10px;
    height: 34px;
}
.tb-bold-line.type03 td {
    padding: 10px 10px;
    height: 50px;
}
.tb-bold-line td {
    text-align: center;
    border-bottom: 1px solid #e6e6e6;
    line-height: 1.2;
}
.tb-bold-line td.al-left {
    text-align: left;
}
.tb-bold-line td.al-right {
    text-align: right;
}
.tb-bold-line tr.on td {
    background: #fcfcfc;
}
.tb-bold-line th.bd-right,
.tb-bold-line td.bd-right {
    border-right: 1px solid #e6e6e6;
}
.tb-bold-line td.bd-left {
    border-left: 1px solid #e6e6e6;
}
.tb-bold-line th.bd-bottom,
.tb-bold-line td.bd-bottom {
    border-bottom: 1px solid #e6e6e6;
}
.tb-bold-line .link {
    border-bottom: 1px solid #222;
    line-height: 1.6;
}
.tb-bold-line .link:hover {
    text-decoration: none;
}
.tb-bold-line .tooltip .tb-default th {
    border-top: 0 none;
    border-bottom: 1px solid #e6e6e6;
    padding-left: 30px;
}
.tb-bold-line .tooltip .tb-default tr:first-child th {
    border-top: 1px solid #b2b2b2;
}
.tb-bold-line .tooltip .tb-default td {
    text-align: left;
}
.tb-product thead th {
    border-top: 1px solid #b2b2b2;
    height: 54px;
    text-align: center;
}
.tb-product td {
    border-top: 1px solid #e3e4e5;
    text-align: center;
    padding: 20px 0;
    background: #fbfbfb;
}
.tb-product.type02 thead th {
    border-top: 0 none;
    background: #f7f7f7;
}
.tb-product.type02 td {
    background: none;
    border-top: 2px solid #e3e4e5;
}
.table-scroll-header {
    margin-top: 55px;
    padding-right: 17px;
    border-top: 2px solid #494e55;
    border-bottom: 2px solid #494e55;
    position: relative;
}
.table-scroll-header caption {
    font-size: 1.2857em;
    position: absolute;
    top: -28px;
    left: 0;
}
.table-scroll-header > table th {
    height: 50px;
}
.table-scroll-body {
    overflow-y: scroll;
    border-bottom: 1px solid #e6e6e6;
}
.table-scroll-body > table td {
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    text-align: center;
}
.table-scroll-body.type02 > table td {
    height: auto;
    text-align: center;
    padding: 12px 0;
    line-height: 1.4;
}
.table-wrap-grey {
    border-top: 2px solid #b2b2b2;
    border-bottom: 2px solid #b2b2b2;
}
table tr.no-list td {
    height: 100px;
}
.tb-terms th,
.tb-terms td {
    padding: 8px 20px;
}
.tb-terms th {
    background: #fbfbfb;
    border-top: 1px solid #b2b2b2;
    border-bottom: 1px solid #b2b2b2;
}
.tb-terms td {
    text-align: center;
    line-height: 1.4;
    border-bottom: 1px solid #e6e6e6;
}
.tb-terms.type2 th {
    height: 39px;
    border-bottom-color: #e6e6e6;
}
.tb-terms.type2 td {
    height: 39px;
}
.tb-terms.type2 th:not(:first-child),
.tb-terms.type2 td:not(:first-child) {
    border-left: 1px solid #e6e6e6;
}

/* list */
.bullet-list li {
    background: url(/img/bizcon/bullet-black.gif) no-repeat 5px 11px;
    padding-left: 12px;
    line-height: 1.6;
    color: #000;

    > .list-sub > li {
        background: none;
    }
}
.red-bullet-list li {
    background: url(/img/bizcon/bullet-red.gif) no-repeat 0 9px;
    padding-left: 14px;
    line-height: 1.6;
}
.red-bullet-list.type02 li {
    font-size: 1.1429em;
}
.red-bullet-list.type03 li {
    font-size: 1.1429em;
    margin-top: 20px;
}
.red-bullet-list.type03 li:first-child {
    margin-top: 0;
}
.red-bullet-list.type03 li p {
    font-size: 0.875em;
}
.hyphen-list li {
    font-size: 1.1429em;
    line-height: 1.6;
}
.hyphen-list.type02 li {
    font-size: 1em;
    line-height: 1.6;
}
.hyphen-list.type03 li {
    font-size: 1em;
    line-height: 1.4;
    margin-top: 5px;
}
.hyphen-list.type03 li:first-child {
    margin-top: 0;
}
.check-list li {
    margin-bottom: 10px;
}
.check-list.type02 li {
    float: left;
    margin: 5px 20px 5px 0;
}
.tb-list li {
    margin-top: 20px;
}
.tb-list li:first-child {
    margin-top: 0;
}
.tit-list li {
    line-height: 1.6;
    margin-top: 28px;
}
.tit-list li:first-child {
    margin-top: 0;
}
.tit-list li h1 {
    font-size: 1.2857em;
    font-weight: 400;
    text-align: left;
}
.tit-list li p {
    margin-left: 20px;
}
.btn-list li {
    float: left;
    margin-right: 10px;
}
.btn-list li button {
    font-weight: 400;
}
.btn-list li button.on {
    font-weight: 700;
    color: #ff5757;
}
.btn-list.type02 li {
    float: left;
    width: 170px;
    padding-left: 20px;
    margin-right: 20px;
    line-height: 2;
}
.btn-list.type02 li:nth-child(5n) {
    margin-right: 0;
}
.btn-list.type03 li {
    margin: 0 20px 10px 0;
}
.btn-list.type03 li button {
    border: 1px solid #cdcdcd;
    padding: 5px 6px;
}
.box-list-bullet {
    padding: 25px 30px;
    border: 1px solid #ddd;
}
.box-list-bullet li {
    line-height: 1.6;
    padding-left: 15px;
    margin-top: 30px;
    background: url(/img/bizcon/bullet-red-middle.png) no-repeat 0 7px;
}
.box-list-bullet li:first-child {
    margin-top: 0;
}
.tb-bullet-list li {
    position: relative;
    padding-left: 6px;
    color: #666;
    line-height: 21px;
}
.tb-bullet-list li:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: #949494;
}
.tb-bullet-list li + li {
    margin-top: 6px;
}

/* 레이어 팝업 */
.layer-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.45;
    filter: Alpha(opacity=45);
    z-index: 100;
    display: none;
}
.layer-close {
    width: 20px;
    height: 20px;
    text-indent: -9999em;
    background: url(/img/bizcon/icon-pop-close.gif) no-repeat 0 0;
    position: absolute;
    top: 20px;
    right: 20px;
}
.layer-close2 {
    width: 20px;
    height: 20px;
    text-indent: -9999em;
    background: url(/img/bizcon/icon-pop-close.gif) no-repeat 0 0;
    position: absolute;
    top: 20px;
    right: 20px;
}
.layer-pop-contents {
    width: 650px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 200;
    background: #fff;
    padding: 40px 30px 30px 30px;
    border-top: 2px solid #ff5888;
    display: none;
}
.layer-pop-contents .txt-title {
    font-size: 1.8571em;
}
.layer-pop-contents p {
    line-height: 1.6;
}
.layer-pop-contents .company_name_plate {
    position: relative;
    display: inline-block;
}
.layer-pop-contents .company_name_plate .company_square_stamp {
    position: absolute;
    top: 50%;
    right: -56px;
    margin-top: -24px;
}
.pop-main-header {
    text-align: center;
    padding: 35px 0 20px;
    border-bottom: 1px solid #a8a8a8;
}
.pop-main-header.type02 {
    padding-bottom: 10px;
}
.pop-main-header .tit {
    font-size: 1.4289em;
}
.pop-main-header .tit strong {
    font-weight: 700;
    letter-spacing: -1px;
    font-size: 1.3em;
    color: #ff5757;
}
.pop-main-header .sub {
    font-size: 1.1429em;
}
.pop-main-body {
    padding: 20px;
}
.pop-main .tit {
    font-size: 1.2857em;
    line-height: 1.6;
}
.pop-main .tit .sub {
    font-size: 0.7778em;
}
.pop-main .strong {
    font-size: 1.5714em;
    color: #ff5757;
}
.pop-main .cmt {
    font-size: 1.1429em;
}
.pop-main-footer {
    padding: 30px 0 10px;
}
.pop-main-footer .ft-con {
    margin: 0 auto;
    padding-left: 213px;
    position: relative;
}
.pop-main-footer .ft-con h1 {
    position: absolute;
    left: 20px;
    top: 5px;

    img {
        width: 153px;
    }
}
.pop-main-footer .ft-info {
    font-size: 0.8571em;
    line-height: 1.4;
    color: #a0a0a0;
}
.layer-pop-contents.type02 .txt-title {
    text-align: center;
    padding-bottom: 30px;
}
.layer-pop-contents.type02 .pop-main {
    border-top: 2px solid #494e55;
}
.alert-comment {
    text-align: center;
    padding: 40px 0;
    line-height: 1.6;
    font-size: 1.4286em;
}
.layer-alert-bg {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.45;
    filter: Alpha(opacity=45);
    z-index: 300;
}
.layer-alert-bg + .layer-pop-contents {
    z-index: 400;
}

.layer-progress-bg {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.45;
    filter: Alpha(opacity=45);
    z-index: 600;
}
.layer-progress-contents {
    width: 650px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 500;
    background: #fff;
    padding: 40px 30px 30px 30px;
    border-top: 2px solid #ff5757;
    display: none;
}

.layer-progress-bg + .layer-progress-contents {
    z-index: 700;
}

/* header */
#header {
    border-top: 2px solid #ff4800;
}
#top {
    width: 1243px;
    padding: 44px 0 40px 37px;
    margin: 0 auto;
    position: relative;
}
#top .banner {
    position: absolute;
    right: 35px;
    bottom: 50px;
}
#user-nav {
    position: absolute;
    right: 0;
    top: 24px;
}
#user-nav li {
    float: left;
    padding: 0 22px;
    background: url(/img/bizcon/bg-line.gif) no-repeat 0 center;
}
#user-nav li:first-child {
    background: none;
}
#user-nav li a:hover {
    text-decoration: none;
}
#user-nav li.login a {
    color: #ff4800;
    font-weight: 700;
}
#user-nav li .txt-red {
    font-weight: 700;
    margin-right: 6px;
}
#user-nav li.logout button {
    color: #959595;
    border-bottom: 1px solid #959595;
}
#user-nav li.last {
    padding-right: 0;
}
.total-search-area {
    width: 504px;
    border: 1px solid #ff5757;
    position: absolute;
    top: 60px;
    left: 50%;
    margin-left: -252px;
    padding: 10px 5px;
    border-radius: 3px;
}
.total-search-area input[type="text"] {
    border: 0 none;
    width: 410px;
    height: auto;
    line-height: 1;
    font-size: 1.1429em;
}
.total-search-area .btn-search {
    width: 72px;
    height: 100%;
    color: #fff;
    background: #ff5757;
    position: absolute;
    right: -1px;
    top: 0;
    border-radius: 0 1px 1px 0;
}

/* gnb */
#gnb {
    background: #3b3a40;
    position: relative;
}
.gnb-nav {
    float: right;
    width: 1031px;
    height: 60px;
}
.gnb-nav > li {
    float: left;
    position: relative;
}
.gnb-nav > li > a {
    display: block;
    color: #fff;
    font-size: 1.1429em;
    background: url(/img/bizcon/bullet-white.gif) no-repeat 0 center;
}
.gnb-nav > li:first-child > a {
    background: none;
}
.gnb-nav > li.gnb-menu > a {
    padding: 22px 0;
    text-align: center;
    font-weight: 700;
    width: 166px;
}
.gnb-nav > li.my-bizcon > a {
    background: #494e55;
    padding: 22px 40px;
}
.gnb-nav > li.basket > a {
    width: 72px;
    text-align: right;
    padding: 22px 16px 22px 0;
    background: #ff5757 url(/img/bizcon/icon-basket.png) no-repeat 20px center;
    margin-left: 40px;
}
.gnb-nav li a:hover {
    text-decoration: none;
}
.gnb-nav > li.gnb-menu > a:hover,
.gnb-nav > li.my-bizcon > a:hover,
.gnb-nav > li.gnb-menu.active > a,
.gnb-nav > li.my-bizcon.active > a,
.gnb-nav > li.gnb-menu.on > a,
.gnb-nav > li.my-bizcon.on > a {
    color: #ff5757;
}
.gnb-nav > li > ul {
    width: 100%;
    position: absolute;
    padding-top: 30px;
    z-index: 20;
    display: none;
}
.gnb-nav > li > ul > li > a {
    display: block;
    /* padding: 15px 0; */
    margin-bottom: 25px;
    font-size: 1.1429em;
    font-weight: 700;
    text-align: center;
}
.gnb-nav > li > ul > li > a:hover {
    color: #ff5757;
}
.gnb-nav > li.my-bizcon > ul {
    margin-top: 5px;
    padding: 20px 0 66px;
    border-left: 1px solid #bfc1c4;
}
.gnb-nav > li.my-bizcon > ul > li a {
    padding-left: 25px;
}
.gnb-nav > li.my-bizcon > ul > li > ul > li {
    line-height: 1.6;
}
.gnb-nav > li.my-bizcon > ul > li > ul > li > a {
    font-size: 1em;
    letter-spacing: -1px;
}
.gnb-nav > li.my-bizcon > ul > li > ul > li > a:hover {
    text-decoration: underline;
}
.gnb-nav > li.gnb-menu.on > ul {
    border-top: 3px solid #ff5757;
    padding-top: 22px;
}
.gnb-bg,
.quick-menu-bg {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #8d8d8d;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 60px;
    z-index: 10;
    display: none;
}
.gnb-bg .page-wrapper {
    height: 300px;
    position: relative;
}
.quick-menu-bg .page-wrapper {
    height: 540px;
    position: relative;
}
.gnb-bg .banner {
    position: absolute;
    top: 100px;
    left: 29px;
}
.quick-menu-bg .banner {
    position: absolute;
    bottom: 40px;
    left: 0;
}
.gnb-bg .banner li,
.quick-menu-bg .banner li {
    float: left;
    margin-right: 50px;
}
.gnb-bg .banner li.banner02,
.gnb-bg .banner li.banner03 {
    margin-top: 52px;
}
.gnb-bg .banner li img,
.quick-menu-bg .banner li img {
    display: block;
}
.gnb-bg .banner li.banner01 img {
    width: 155px;
    height: 179px;
}
.gnb-bg .banner li.banner02 img,
.quick-menu-bg .banner li.banner02 img {
    width: 155px;
    height: 90px;
}
.gnb-bg .banner li.banner03 img,
.quick-menu-bg .banner li.banner03 img {
    width: 350px;
    height: 90px;
}

/* 빠른상품찾기 */
a.quick-menu-open {
    color: #fff;
    font-size: 1.1429em;
    font-weight: 700;
    background: #494e55 url(/img/bizcon/icon-triangle-white.png) no-repeat 180px center;
    padding: 22px 56px 25px 22px;
    line-height: 1.2;
    position: absolute;
    left: 50%;
    margin-left: -640px;
    top: 0;
    z-index: 30;
}
a.quick-menu-open.on {
    border-bottom: 2px solid #ff5757;
}
a.quick-menu-open:hover {
    text-decoration: none;
}
.quick-menu-open span {
    display: inline-block;
    width: 20px;
    height: 14px;
    background: url(/img/bizcon/icon-list.png) no-repeat 0 0;
    margin-right: 22px;
    vertical-align: middle;
}
.quick-menu .page-wrapper {
    position: relative;
}
.product-more {
    display: inline-block;
    text-indent: -9999em;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 35px;
}
.product-more:before,
.product-more:after {
    content: "";
    display: block;
    position: absolute;
    background: #ff5757;
}
.product-more:before {
    width: 24px;
    height: 1px;
    top: 50%;
    left: 0;
}
.product-more:after {
    width: 1px;
    height: 24px;
    top: 0;
    left: 50%;
}
.quick-list {
    width: 100%;
    position: absolute;
    padding-top: 36px;
    z-index: 20;
    display: none;
}
.quick-list > ul > li {
    float: left;
    margin: 0 20px;
    width: 156px;
}
.quick-list > ul > li > a {
    display: block;
    padding-bottom: 12px;
    margin-bottom: 16px;
    color: #999;
    border-bottom: 1px solid #e3e3e3;
}
.quick-list > ul > li a {
    font-weight: 700;
}
.quick-list > ul > li a:hover {
    color: #ff5757;
    text-decoration: none;
}
.quick-list > ul > li > ul > li > a {
    display: block;
    padding: 8px 0;
}

/* footer */
#footer {
    border-top: 1px solid #e1e1e1;
    padding: 30px 0 60px;
}
.footer-contents {
    width: 1030px;
    margin: 0 auto;
    padding-left: 250px;
    position: relative;
}
.footer-contents h1 {
    position: absolute;
    left: 35px;
    top: 5px;
}
.footer-contents h2 {
    position: absolute;
    right: 73px;
    top: 5px;
}
.footer-nav li {
    float: left;
    font-size: 1.1429em;
    border-left: 1px solid #222;
    padding: 0 12px;
}
.footer-nav li:first-child {
    padding-left: 0;
    border-left: 0 none;
}
.footer-info {
    margin-top: 24px;
    font-size: 0.8571em;
    line-height: 1.4;
    color: #a0a0a0;
}
.family-site {
    position: absolute;
    right: 20px;
    bottom: 10px;
}
.family-site button {
    padding: 8px 82px 8px 10px;
    border: 1px solid #e6e6e6;
    position: relative;
}
.family-site button span {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 28px;
    height: 100%;
    border-left: 1px solid #e6e6e6;
    background: #f1f1f1 url(/img/bizcon/arrow-down-line.png) no-repeat center center;
}
.family-site ul {
    width: 100%;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    box-sizing: border-box;
    position: absolute;
    bottom: 33px;
    left: 0;
    display: none;
}
.family-site ul li a {
    display: block;
    padding: 8px 10px;
    border-top: 1px solid #e6e6e6;
    background: #fff;
}

/* contents */
.page-wrapper,
#contents-main {
    width: 1280px;
    margin: 0 auto;
}
#contents-nav {
    background: #f7f7f7;
}
.nav-list {
    float: right;
}
.nav-list > li {
    float: left;
    position: relative;
    margin-left: -1px;
}
.nav-list > li:first-child {
    background: #a2a2a2;
    color: #fff;
    font-weight: 800;
    font-size: 1.2857em;
    padding: 14px;
    border: 1px solid #a2a2a2;
}
.nav-list > li > button {
    display: block;
    height: 48px;
    padding: 0 40px 0 20px;
    background: #fff;
    border: 1px solid #dedede;
    box-sizing: border-box;
    position: relative;
}
.nav-list > li:last-child > button {
    border-right: 1px solid #fff;
}
.nav-list > li > button:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-right: 4px solid transparent;
    border-top: 4px solid #222;
    border-left: 4px solid transparent;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -2px;
}
.nav-list > li > button[disabled] {
    background: #f7f7f7;
    color: #222;
}
.nav-list > li > button[disabled]:after {
    border-top: 4px solid #c6c6c6;
}
.nav-list > li:last-child > button[disabled] {
    border-right: 1px solid #f7f7f7;
}
.nav-list > li > ul {
    position: absolute;
    left: 0;
    top: 48px;
    border-left: 1px solid #dedede;
    border-right: 1px solid #dedede;
    box-sizing: border-box;
    width: 100%;
    display: none;
    z-index: 1;
}
.nav-list > li > ul > li > a {
    display: block;
    padding: 14px 20px;
    color: #808080;
    font-size: 0.9286em;
    background: #fff;
    border-bottom: 1px solid #dedede;
}
.nav-list > li > ul > li > a:hover {
    text-decoration: none;
}
#contents-main {
    /* min-height: 880px; */
    min-height: 650px;
}
.contents-title {
    padding: 62px 0 45px;
}
.contents-title h1 {
    font-size: 2.5714em;
    text-align: center;
    letter-spacing: -2px;
}
.contents-title p {
    font-size: 1.2857em;
    text-align: center;
    margin-top: 25px;
    line-height: 1.2;
}
.contents-title-02 {
    padding: 48px 0 0;
}
.contents-title-02 p {
    margin-top: 18px;
    font-size: 1.4286em;
    line-height: 1.2;
}
.contents-title-03 {
    padding: 48px 0 92px;
    position: relative;
}
.contents-title-04 {
    padding: 70px 0 30px;
    text-align: center;
}
.contents-title-04 h1 {
    font-size: 2.8em;
    letter-spacing: 4px;
    margin-bottom: 30px;
}
/* 2018-12-28 추가 */
.contents-title-05 {
    padding: 0;
    margin-bottom: 85px;
    text-align: center;
}
.contents-title-05 img {
    vertical-align: top;
}
/* 2019-01-02 추가 */
.contents-title-06 h1 {
    font-size: 2.2em;
    font-weight: normal;
    letter-spacing: -0.025em;
    line-height: 1em;
}
/* 2019-10-11 추가 */
.contents-title-07 {
    padding: 0;
    margin-bottom: 55px;
    text-align: center;
}
.contents-title-07 img {
    vertical-align: top;
}
.contents-box {
    margin-top: 30px;
}
.contents-box > h1 {
    font-size: 1.2857em;
    font-weight: 400;
    margin-bottom: 12px;
}
.line-bg-box {
    border-top: 1px dashed #c0c0c0;
    background: #fbfbfb;
    padding: 30px 20px;
}
.line-bg-box > h1 {
    font-size: 1.2857em;
    font-weight: 400;
    padding-bottom: 10px;
}
.line-tb-box {
    border-top: 1px solid #b2b2b2;
    border-bottom: 1px solid #e6e6e6;
    padding: 20px;
    line-height: 1.6;
}
.line-tb-box > h1 {
    font-size: 1.1429em;
    margin-bottom: 18px;
}
.tail-comment {
    font-size: 1.1429em;
    font-weight: 700;
    text-align: center;
    line-height: 1.6;
}
.search-area {
    padding: 40px 0;
    text-align: center;
    background: #f7f7f7;
}
.search-area .btn-search {
    text-indent: -9999em;
    width: 40px;
    border-radius: 0;
    background: #3b3a40 url(/img/bizcon/icon-search-white.png) no-repeat center center;
}

/* 탭 메뉴 */
.tab:after {
    content: "";
    display: block;
    clear: both;
}
.tab > li {
    float: left;
    width: 240px;
    margin-right: 10px;
}
.tab > li a {
    display: block;
    width: 100%;
    padding: 18px 0;
    text-align: center;
    border: 1px solid #e6e6e6;
    border-bottom: 1px solid #ff5757;
    background: #fff;
}
.tab > li.on a {
    color: #ff5757;
    font-weight: 700;
    border: 1px solid #ff5757;
    border-bottom: 1px solid #fff;
}
.tab > li a:hover {
    text-decoration: none;
}
.tab-contents {
    margin-top: -1px;
    padding: 30px 20px;
    min-height: 140px;
    border-top: 1px solid #ff5757;
    background: #fff;
}
.tab-contents-main {
    position: relative;
}

.tab.type02 > li a:hover,
.tab.type02 > li.on a {
    color: #ff5757;
    border: 0 none;
    border-left: 1px solid #e6e6e6;
}
.tab.type02 > li:first-child a {
    border-left: 0 none;
}
.tab.type03 {
    float: left;
    width: 285px;
    border-top: 3px solid #000;
}
.tab.type03 li {
    float: none;
    width: 100%;
}
.tab.type03 li a {
    text-align: left;
    border: 0 none;
    border-bottom: 1px solid #cfcfcf;
    background: url(/img/bizcon/arrow-left-line-s.png) no-repeat 270px center;
}
.tab.type03 li a:hover,
.tab.type03 > li.on a {
    color: #ff5757;
    border: 0 none;
    border-bottom: 1px solid #cfcfcf;
    background: url(/img/bizcon/arrow-left-line-s-red.png) no-repeat 270px center;
}
.tab-contents.type02 {
    border-top: 0 none;
    padding: 30px 0;
    margin-top: 0;
}
.tab-contents.type02 .tabTitle p {
    text-align: center;
    font-size: 1.4289em;
    padding: 5px 0;
    border: 0 none;
    font-weight: 700;
}
.tab-contents.type03 {
    border-top: 0 none;
    padding: 0;
    margin-top: 0;
    float: right;
    width: 905px;
    position: relative;
}

/* 로그인, 아이디 찾기 */
.user-form,
.user-btn-grp {
    width: 470px;
    margin: 0 auto;
}
.user-form {
    margin-top: 35px;
    position: relative;
}
.user-form .btn-login {
    position: absolute;
    right: 0;
    top: 0;
}
.user-btn-grp {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #dbdbdb;
    text-align: center;
}
.user-btn-grp li {
    display: inline-block;
    margin-left: 2px;
}
.user-btn-grp li:first-child {
    margin-left: 0;
}
.timer {
    display: inline-block;
    min-height: 16px;
    padding-left: 18px;
    background: url("/img/bizcon/ico-timer.png") no-repeat 0 center / auto 16px;
    color: #ff5757;
    font-weight: 700;
}

/* 회원가입 */
.process-box {
    border-top: 1px solid #a8a8a8;
    padding: 40px 0 70px;
}
.process-box .txt-title {
    float: left;
    line-height: 1.2;
    width: 280px;
}
.process-box-contents {
    float: left;
    padding: 40px 50px;
    width: 899px;
    border-left: 1px solid #ddd;
}
.process-box-contents > ul > li {
    margin-top: 35px;
}
.process-box-contents > ul > li:first-child {
    margin-top: 0;
}
.process-box-contents > ul > li > h1 {
    font-size: 1.2857em;
    float: left;
    width: 90px;
}
.process-box-contents > ul > li > h1 + div {
    float: left;
    width: 809px;
    line-height: 1.4;
}
.sign-process {
    width: 674px;
    height: 160px;
    text-indent: -9999em;
    background: url(/img/bizcon/img-sign-process.gif) no-repeat 0 0;
}
.process-step-grp {
    display: inline-block;
}
.process-step-grp li {
    float: left;
    width: 225px;
    text-align: center;
    position: relative;
}
.process-step-grp li:after {
    content: "";
    display: block;
    width: 44px;
    height: 0;
    border-top: 1px solid #ddd;
    position: absolute;
    left: -24px;
    top: 38px;
}
.process-step-grp li:first-child:after {
    display: none;
}
.process-step-grp li > div {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 1px solid #d0d2d2;
    margin: 0 auto;
}
.process-step-grp li > p {
    color: #555;
    font-size: 1.1429em;
    margin-top: 20px;
    display: inline-block;
    line-height: 1.4;
}
.process-step-grp li.on > div {
    border: 1px solid #ff5757;
}
.process-step-grp li.on > p {
    color: #ff5757;
    border-bottom: 1px solid #ff5757;
}
.process-step-grp li.step01 > div {
    background: url(/img/bizcon/icon-step01.png) no-repeat center center;
}
.process-step-grp li.step02 > div {
    background: url(/img/bizcon/icon-step02.png) no-repeat center center;
}
.process-step-grp li.step03 > div {
    background: url(/img/bizcon/icon-step03.png) no-repeat center center;
}
.process-step-grp li.step04 > div {
    background: url(/img/bizcon/icon-step04.png) no-repeat center center;
}
.process-step-grp li.step05 > div {
    background: url(/img/bizcon/icon-step05.png) no-repeat center center;
}
.process-step-grp li.on.step01 > div {
    background: url(/img/bizcon/icon-step01-on.png) no-repeat center center;
}
.process-step-grp li.on.step02 > div {
    background: url(/img/bizcon/icon-step02-on.png) no-repeat center center;
}
.process-step-grp li.on.step03 > div {
    background: url(/img/bizcon/icon-step03-on.png) no-repeat center center;
}
.process-step-grp li.on.step04 > div {
    background: url(/img/bizcon/icon-step04-on.png) no-repeat center center;
}
.process-step-grp li.on.step05 > div {
    background: url(/img/bizcon/icon-step05-on.png) no-repeat center center;
}
.signUp-box {
    margin-top: 40px;
    padding: 40px 0 60px;
    border-top: 1px solid #a8a8a8;
}
.signUp-box > header {
    padding-top: 60px;
    position: relative;
}
.signUp-box > header > p {
    font-size: 1.2857em;
    position: absolute;
    top: 0;
    left: 0;
}
.signUp-box > footer {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
}
.signUp-box-contents,
.box-contents {
    margin-top: 40px;
}
.signUp-box-contents > h1,
.box-contents > h1 {
    font-size: 1.7143em;
    font-weight: 400;
    margin-bottom: 10px;
}
.box-contents {
    position: relative;
}
.box-contents > h1 span {
    font-size: 0.5833em;
    margin-left: 20px;
}
.box-contents.type02 > h1 {
    font-size: 1.5714em;
    letter-spacing: -2px;
}
.box-contents .product-more {
    top: 0;
}
.scroll-box {
    height: 238px;
    border: 1px solid #ddd;
    overflow-y: scroll;
    position: relative;
}
.signUp-complate {
    padding-top: 70px;
    text-align: center;
}
.signUp-complate p {
    font-size: 1.7143em;
    line-height: 1.2;
}
.signUp-complate p.middle {
    font-size: 1.2857em;
}
.signUp-complate p.last {
    font-size: 2.1429em;
    margin-top: 40px;
}
.time-count {
    display: inline-block;
    font-size: 1em;
    width: 150px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
    vertical-align: middle;
    border-radius: 3px;
    color: #ff5757;
    border: 1px solid #ff5757;
    background: #fff;
    box-sizing: border-box;
}

/* 약관 */
.terms {
    padding: 30px 20px;
    line-height: 1.6;
}
.terms h1 {
    font-size: 1em;
}
.terms-scroll-box {
    padding: 30px;
    border: 1px solid #ddd;
    overflow-y: scroll;
    height: 460px;
    line-height: 1.6;
    position: relative;
}

/* 대량발송 관리 */
.sub-contents-box {
    min-height: 666px;
    padding-bottom: 60px;
}
.product img {
    display: block;
    float: left;
    width: 98px;
    height: 98px;
    border: 1px solid #e6e6e6;
}
.product figcaption {
    float: left;
    width: 215px;
    padding: 20px 0 0;
    margin-left: 10px;
    line-height: 1.4;
    font-weight: 700;
}
.cover-price,
.saving-price {
    font-size: 1.2857em;
    font-weight: 700;
}
.saving-price.type02 {
    font-size: 1.7143em;
}
.cover-price .won,
.saving-price .won {
    color: #fff;
    border-radius: 3px;
    margin-right: 5px;
    padding: 0 1px;
    font-size: 0.6667em;
    position: relative;
    top: -2px;
}
.cover-price .won {
    background: #b0b0b0;
    border: 1px solid #8e8e8e;
}
.saving-price .won {
    background: #ff6762;
    border: 1px solid #df423d;
}
.cover-price .price {
    color: #333;
}
.saving-price .price {
    color: #ff6762;
    letter-spacing: -1px;
}
.best-interest > h1 {
    font-size: 1.5714em;
}
.best-interest-list li {
    float: left;
    width: 33.3334%;
    max-height: 200px;
    padding: 20px;
    border-left: 1px solid #e6e6e6;
    box-sizing: border-box;
}
.best-interest-list li:first-child {
    border-left: 0 none;
}
.best-interest-list .product {
    padding: 20px 0;
}
.best-interest-list .product img {
    width: 120px;
    height: 120px;
    border: 0 none;
}
.best-interest-list .product figcaption {
    padding-top: 5px;
    margin-left: 15px;
    line-height: 1;
}
.product .brand {
    color: #999;
    font-size: 1em;
    display: block;
    margin-bottom: 12px;
    height: 16px;
    overflow: hidden;
}
.product .product-name {
    font-size: 1.2857em;
    display: block;
    margin-bottom: 20px;
    line-height: 1.3;
    height: 46px;
    overflow: hidden;
}

/* 퀵메뉴 */
.side-bar {
    position: absolute;
    top: 373px;
    left: 50%;
    margin-left: 680px;
}
.side-bar-box {
    width: 87px;
    box-sizing: border-box;
    border: 1px solid #e1e1e1;
    border-top: 2px solid #3b3a40;
    padding: 0 6px;
    background: #fff;
}
.side-bar-box > h1 {
    font-weight: 400;
    text-align: center;
    padding: 20px 0 10px;
    border-bottom: 1px solid #cfcfcf;
    font-size: 1em;
}
.side-bar-box > h1 span {
    display: block;
    font-size: 1.1429em;
    margin-top: 8px;
}
.side-bar-list {
    min-height: 183px;
}
.side-bar-list,
.side-bar-list ul li {
    padding: 10px 0;
}
.side-bar-list ul li:nth-child(2n + 1) {
    border-bottom: 1px dashed #cfcfcf;
}
.side-bar-list ul li a,
.side-bar-list ul li a img {
    display: block;
    width: 71px;
    height: 71px;
}
.side-paging button {
    display: inline-block;
    text-indent: -9999em;
    width: 50%;
    height: 30px;
    float: left;
}
.side-paging .prev {
    background: url(/img/bizcon/arrow-left-line.gif) no-repeat 18px center;
}
.side-paging .next {
    background: url(/img/bizcon/arrow-right-line.gif) no-repeat 10px center;
}
a.just-move {
    display: block;
    background: #5b5b5b;
    color: #fff;
    text-align: center;
    padding: 12px 0;
    margin: 0 -6px;
    font-size: 0.8571em;
}
a.move-top {
    display: block;
    width: 30px;
    height: 30px;
    padding-top: 15px;
    box-sizing: border-box;
    background: #5b5b5b;
    border-radius: 50%;
    margin: 10px auto 0;
    color: #fff;
    font-size: 0.5em;
    font-weight: 800;
    text-align: center;
    position: relative;
}
a.move-top:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    border-bottom: 4px solid #fff;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    top: 7px;
    left: 50%;
    margin-left: -3px;
}
a.just-move:hover,
a.move-top:hover {
    text-decoration: none;
}
/* 2019-01-04 추가 */
.side-down-list {
    border: 1px solid #e1e1e1;
}
.side-down-list.fir {
    border-bottom: none;
}

/* tooltip */
.tooltip-open {
    text-indent: -9999em;
    width: 24px;
    height: 24px;
    background: url(/img/bizcon/icon-help.png) no-repeat 0 0;
    position: relative;
    top: -1px;
}
.tooltip-open-intooltip {
    position: relative;
    top: -1px;
    background-color: gray;
    width: 60px;
    height: 30px;
    color: white;
    font-weight: bold;
}
.tooltip-open.type02 {
    text-indent: 0;
    width: auto;
    height: 34px;
    color: #fff;
    font-weight: 700;
    text-align: left;
    padding: 0 24px 0 54px;
    border-radius: 3px;
    background: #ff5757 url(/img/bizcon/icon-help-white.png) no-repeat 24px center;
    position: relative;
    top: 0;
}
.tooltip-box {
    display: inline-block;
    position: relative;
}
.tooltip {
    position: absolute;
    top: 30px;
    left: -100%;
    width: 600px;
    padding: 20px 20px 40px;
    border: 1px solid #494e55;
    border-top: 2px solid #ff5757;
    background: #fff;
    box-sizing: border-box;
    font-weight: 400;
    letter-spacing: -1px;
    z-index: 1;
    display: none;
}
.tooltip-open.type02 + .tooltip {
    top: 36px;
    left: auto;
    right: 0;
    width: 470px;
}
.tooltip .tit {
    font-size: 1.2857em;
    font-weight: 400;
    padding-left: 35px;
    margin-bottom: 20px;
    height: 24px;
    line-height: 24px;
    text-align: left;
    background: url(/img/bizcon/icon-help.png) no-repeat 0 0;
}
.tooltip p {
    text-align: left;
    font-weight: 400;
    line-height: 1.4;
}
.tooltip2 {
    position: absolute;
    top: 30px;
    left: -100%;
    width: 600px;
    padding: 20px 20px 40px;
    border: 1px solid #494e55;
    border-top: 2px solid #ff5757;
    background: #fff;
    box-sizing: border-box;
    font-weight: 400;
    letter-spacing: -1px;
    z-index: 1;
    display: none;
}
.tooltip-open.type02 + .tooltip2 {
    top: 36px;
    left: auto;
    right: 0;
    width: 1000px;
}
.tooltip2 .tit {
    font-size: 1.2857em;
    font-weight: 400;
    padding-left: 35px;
    margin-bottom: 20px;
    height: 24px;
    line-height: 24px;
    text-align: left;
    background: url(/img/bizcon/icon-help.png) no-repeat 0 0;
}

/* 주문, 결제 */
.order-contents-box {
    border-top: 1px solid #a8a8a8;
    padding-bottom: 60px;
}
.product-send-list > li {
    margin-top: 30px;
    border: 1px solid #a8a8a8;
    border-radius: 5px;
    padding: 40px 40px 20px;
    position: relative;
}
.product-send-list > li.on {
    border: 1px solid #ff5757;
}
.product-send-grp > header > h1 {
    font-size: 1.2857em;
    display: inline-block;
    margin-left: -15px;
}
.tb-product .product {
    display: inline-block;
}
.tb-product .product figcaption {
    text-align: left;
    padding-top: 15px;
    line-height: 1.2;
    width: 260px;
}
.tb-product.type02 .product figcaption {
    width: 200px;
}
.tb-product .product .brand {
    margin-bottom: 8px;
}
.sales-price {
    display: block;
    color: #ff5757;
    font-weight: 700;
}
.sales-price em {
    font-size: 1.2857em;
}
.discount {
    display: block;
    margin-top: 8px;
}
.total-price em {
    font-size: 1.7143em;
    font-weight: 800;
    letter-spacing: -1px;
    margin-right: 2px;
}
.order-detail-open {
    position: relative;
    color: #fff;
    border: 1px solid #ff5757;
    background: #ff5757;
    width: 180px;
    height: 40px;
    font-size: 1em;
    text-align: center;
    font-weight: 700;
    vertical-align: middle;
    border-radius: 3px;
    transition: all 0.3s;
}
.order-detail-open:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 4px solid #fff;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 0 none;
    position: absolute;
    right: 64px;
    top: 50%;
    margin-top: -2px;
}
.order-detail-open.on {
    color: #ff5757;
    border: 1px solid #ff5757;
    background: #fff;
}
.order-detail-open.on:after {
    border-top: 0 none;
    border-bottom: 4px solid #ff5757;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
}
.product-send-list .value-clear {
    position: absolute;
    top: 20px;
    right: 60px;
}
.product-send-info {
    border-top: 1px dashed #c0c0c0;
    border-bottom: 1px solid #b2b2b2;
    padding: 0 20px 30px;
    background: #fbfbfb;
}
.receiver-count {
    width: 125px;
    height: 85px;
    padding-top: 40px;
    text-align: center;
    color: #fff;
    font-size: 1.2857em;
    background: #494e55;
    border-radius: 50%;
    position: absolute;
    top: 30px;
    right: 40px;
}
.receiver-count > span {
    display: block;
    margin-bottom: 8px;
}
.receiver-count .tit,
.receiver-count .num {
    font-weight: 700;
}
.receiver-count .num {
    font-size: 1.3333em;
    margin: 0 2px;
}
.send-list-box {
    width: 800px;
    height: 88px;
    padding: 20px;
    border: 1px solid #ddd;
    margin-top: 10px;
    overflow-y: scroll;
}
.send-list-box ul li {
    line-height: 1.6;
}
.send-list-box li > span {
    margin-right: 5px;
}
.send-list-box .delete {
    width: 14px;
    height: 14px;
    border: 1px solid #a6a6a6;
    text-indent: -9999em;
    vertical-align: middle;
    background: url(/img/bizcon/icon-del.gif) no-repeat center center;
}
.message-contents {
    padding-top: 35px;
    border-top: 1px dashed #c0c0c0;
}
.message-contents > header {
    padding-bottom: 15px;
    border-bottom: 1px solid #b2b2b2;
    position: relative;
}
.message-contents > header .tooltip-box {
    position: absolute;
    right: 0;
    bottom: 15px;
}
.message-contents-section {
    margin-top: 18px;
}
.message-contents-section > header {
    padding: 0 10px 10px;
    border-bottom: 1px solid #e6e6e6;
}
.message-contents-section > header h1 {
    font-size: 1em;
}
.message-contents-section > header .no {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 1.1426em;
    color: #fff;
    background: #ff5757;
    border-radius: 50%;
    margin-right: 5px;
}
.message-contents-section > header p {
    margin: 5px 0 0 35px;
}
.message-contents-section > div {
    padding: 10px;
    border-bottom: 1px solid #e6e6e6;
}
.message-contents > footer p {
    margin: 10px 0 0 40px;
}
.pop-main.message-contents {
    padding-top: 12px;
}
.pop-main .message-contents-section > header {
    border-bottom: 1px solid #b2b2b2;
    letter-spacing: -1px;
}
.pop-main .message-contents-section > header h1 {
    font-size: 1.2857em;
    font-weight: 400;
}
.pop-main .message-contents-section > header h1 .sub {
    font-size: 0.7778em;
    margin-left: 5px;
    color: #555;
}
.pop-main .message-contents-section > div {
    padding: 10px 20px;
}
.pop-main.message-contents p {
    padding: 10px 20px 0;
    margin: 0;
}
.message-guide {
    position: relative;
    height: 910px;
    height: 716px;
    margin-bottom: -40px;
    background: url(/img/bizcon/img-message-guide.png) no-repeat center bottom;
}
.message-guide img {
    width: 60px;
    position: absolute;
    top: 142px;
    left: 90px;
}
.message-guide .txt {
    text-indent: -9999em;
}
.layer-pop-contents .best-interest-list li {
    max-height: 190px;
    padding: 20px 10px;
}
.layer-pop-contents .product figcaption {
    width: 160px;
    text-align: left;
    padding-top: 10px;
}
.layer-pop-contents .best-interest-list .product img {
    width: 100px;
    height: 100px;
}
.layer-pop-contents .best-interest-list .product figcaption {
    width: 145px;
    padding-top: 0;
    margin-left: 10px;
}
.mms-view-box {
    height: 708px;
    margin: 30px 0 -30px;
    padding-top: 80px;
    background: url(/img/bizcon/bg-phone.png) no-repeat center 0;
}
.mms-scroll {
    width: 270px;
    height: 477px;
    margin: 0 auto 170px;
    background: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
}
.mms-scroll img {
    width: 100%;
}
.mms-comment textarea[readonly] {
    border: 0;
    background: transparent;
    padding: 10px 20px;
}
.user-comment {
    line-height: 1.4;
    padding: 20px;
}
.glass-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 481px;
    background: url(/img/bizcon/bg-phone-shadow.png) no-repeat 0 0;
}
.payment-contents-box > h1 {
    font-size: 1.7143em;
    font-weight: 400;
    padding-bottom: 10px;
}
.payment-product-list li {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px dashed #c0c0c0;
}
.payment-product-list li:first-child {
    padding-top: 0;
    margin-top: 0;
    border-top: 0 none;
}
.payment-result {
    border-top: 2px solid #b2b2b2;
    border-bottom: 1px solid #b2b2b2;
}
.payment-result .fl-left {
    padding: 30px 0;
    width: 72.890625%;
}
.payment-result .fl-left h1 {
    font-size: 1.2857em;
    font-weight: 400;
    padding-left: 35px;
    width: 19%;
    box-sizing: border-box;
    float: left;
}
.payment-result .fl-left .payment-type {
    width: 81%;
    box-sizing: border-box;
    float: left;
}
.payment-result .fl-right {
    width: 27.109375%;
    background: #ff5757;
    color: #fff;
    padding: 25px 20px 10px 20px;
    box-sizing: border-box;
}
.payment-price li {
    padding-bottom: 10px;
}
.payment-price li:after {
    content: "";
    display: block;
    clear: both;
}
.payment-price li > span {
    float: left;
}
.payment-price li > em {
    float: right;
}
.payment-price li > em > span {
    margin-right: 3px;
}
.payment-price li.last {
    margin-top: 15px;
    font-size: 1.2857em;
    font-weight: 700;
}
.payment-price li.last > em > span {
    font-size: 1.4444em;
    letter-spacing: -1px;
}
.underline-list li {
    padding: 0 35px;
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #e6e6e6;
}
.underline-list .num {
    margin-right: 25px;
}
.underline-list li .btn-grp button {
    height: 30px;
    line-height: 30px;
}
.underline-list.type02 li .tel {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
}
.underline-list.type02 li .tel[contentEditable="true"] {
    border: 1px solid #e6e6e6;
    padding: 0 20px;
    min-width: 150px;
}

/* 메인 */
#header-top-banner {
    text-align: center;
    color: #fff;
    font-size: 1.1429em;
    padding: 14px 0;
    background: #ff4800;
    position: relative;
}
.header-banner-close {
    text-indent: -9999em;
    width: 22px;
    height: 22px;
    background: url(/img/bizcon/icon-banner-close.png) no-repeat center center;
    position: absolute;
    top: 10px;
    left: 50%;
    margin-left: 618px;
}
#contents-top-banner {
    border-bottom: 1px solid #e6e6e6;
}
.main-top-slide {
    width: 1280px;
    margin: 0 auto;
    padding-top: 6px;
}
.login-bottom-slide {
    width: 1280px;
    margin: 0 auto;
    padding-top: 50px;
}
.slide-view {
    float: left;
    width: 860px;
    height: 479px;
}
.slide-view-login {
    width: 860px;
    height: 157px;
    margin: auto;
    padding-bottom: 20px;
}
.slide-thum {
    float: right;
    width: 400px;
    padding-top: 40px;
}
.slide-thum ul li {
    float: left;
    width: 50%;
    margin: 0 -1px -1px 0;
    position: relative;
}
.slide-thum ul li button {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
}
.slide-thum ul li button img {
    display: block;
    width: 198px;
    height: 198px;
}
.product-list {
    position: relative;
}

.product-list li {
    float: left;
    width: 33.3333%;
    height: 248px;
    padding: 30px 10px 30px 40px;
    margin-bottom: 20px;
    box-sizing: border-box;
    position: relative;
}
.product-list li:nth-child(2),
.product-list li:nth-child(5) {
    border-right: 1px solid #e6e6e6;
}
.product-list li:not(:nth-child(3n)) {
    border-left: 1px solid #e6e6e6;
}

/* 중앙선 길게 고정 */
.product-list li:nth-child(4n):before {
    content: "";
    display: block;
    width: 1280px;
    height: 0;
    border-top: 1px solid #e6e6e6;
    position: absolute;
    top: -1px;
    left: 0;
    margin-top: -11px;
}

.product-list li:nth-child(3n + 1) {
    border-left: 0 none;
}
.product-list .product > a {
    display: inline-block;
    padding-top: 20px;
    float: left;
    height: 140px;
}
.product-list .product img {
    width: 140px;
    height: 140px;
    border: 0 none;
}
.product-list .product figcaption {
    padding: 0;
    margin-left: 50px;
    width: 170px;
    line-height: 1.2;
}
.product-list .cover-price,
.product-list .saving-price,
.product-list .sale-comment {
    display: block;
}
.product-list .cover-price .price,
.product-list .sale-comment {
    color: #999;
}
.product-list .saving-price .price {
    font-size: 1.9444em;
    letter-spacing: -2px;
}
.product-list .sale-comment {
    font-weight: 400;
    height: 34px;
    overflow: hidden;
}
.bubble-red {
    color: #fff;
    width: 70px;
    height: 50px;
    padding-top: 20px;
    text-align: center;
    background: #ff5757;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 130px;
}
.bubble-red em {
    display: block;
    font-size: 1.4286em;
}
.bubble-red:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    border-top: 12px solid #ff5757;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    position: absolute;
    bottom: 2px;
    transform: rotate(45deg);
}
.thema-product-list li {
    float: left;
    width: 275px;
    margin-left: 40px;
    position: relative;
}
.thema-product-list li:nth-child(3n + 1) {
    margin-left: 0;
}
.thema-product > .product-images {
    width: 273px;
    height: 290px;
    padding-top: 18px;
    border: 1px solid #e6e6e6;
    display: block;
}
.thema-product > .product-images img {
    width: 273px;
    height: 273px;
    display: block;
}
.thema-product > figcaption {
    padding-top: 10px;
}
.thema-product > figcaption > span,
.thema-product > figcaption > a {
    display: block;
}
.thema-product > figcaption .brand {
    color: #999;
    margin-bottom: 5px;
    height: 14px;
    overflow: hidden;
}
.thema-product > figcaption .product-name {
    font-weight: 700;
    height: 14px;
    overflow: hidden;
}
.thema-product > figcaption > div {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #ccc;
}
.thema-product > figcaption .saving-price {
    display: inline-block;
    width: 110px;
}
.thema-product > figcaption .saving-price .won {
    top: -1px;
}
.thema-product > figcaption .saving-price .price {
    font-size: 0.7778em;
}
.thema-product-list .links {
    width: 275px;
    height: 310px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: -1;
}
.thema-product-list .links .bg {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.45;
    filter: alpha(opacity=45);
}
.thema-product-list .links [class*="btn-"] {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -65px;
}
.thema-product-list .links .btn-white {
    top: 110px;
}
.thema-product-list .links .btn-line-white {
    top: 164px;
}
.tag-best,
.tag-sale {
    display: inline-block;
    width: 34px;
    height: 16px;
    line-height: 16px;
    font-weight: 700;
    font-size: 0.5em;
    text-align: center;
    vertical-align: middle;
    margin-right: 2px;
}
.tag-best {
    color: #009cff;
    border: 1px solid #009cff;
}
.tag-sale {
    color: #ff4800;
    border: 1px solid #ff4800;
}
.thema-paging {
    position: absolute;
    right: 0;
    top: -47px;
}
.thema-paging .prev,
.thema-paging .next {
    display: inline-block;
    text-indent: -9999em;
    width: 25px;
    height: 25px;
    border: 1px solid #e6e6e6;
    margin: 0 2px;
}

.thema-paging2 .prev,
.thema-paging2 .next {
    display: inline-block;
    text-indent: -9999em;
    width: 25px;
    height: 25px;
    border: 1px solid #e6e6e6;
    margin: 0 2px;
}

.thema-paging .prev {
    background: #fff url(/img/bizcon/arrow-left-line-red.png) no-repeat center center;
}
.thema-paging .next {
    background: #fff url(/img/bizcon/arrow-right-line-red.png) no-repeat center center;
}
.thema-paging2 .prev {
    background: #fff url(/img/bizcon/arrow-left-line-red.png) no-repeat center center;
    position: absolute;
    right: 405px;
    bottom: 35px;
}
.thema-paging2 .next {
    background: #fff url(/img/bizcon/arrow-right-line-red.png) no-repeat center center;
    position: absolute;
    right: 375px;
    bottom: 35px;
}

.sKeyword {
    text-align: center;
    width: 36%;
    margin-left: 29%;
    overflow: hidden;
    white-space: nowrap;
}

.sKeyword a {
    font-size: 1.2em;
    margin-right: 15px;
    overflow: hidden;
}

.texture-bg {
    border-top: 1px solid #cac9c8;
    border-bottom: 1px solid #cac9c8;
    background: url(/img/bizcon/bg-grey.jpg) repeat-x 0 0;
}
.inline-list .main-information {
    float: left;
    width: 50%;
    height: 299px;
    padding-top: 70px;
    box-sizing: border-box;
}
.inline-list .main-information:first-child {
    border-right: 1px solid #cac9c8;
}
.main-information {
    text-align: center;
    position: relative;
}
.main-information > h1 {
    font-size: 2.2857em;
    padding-bottom: 30px;
}
.main-information > p {
    margin-bottom: 20px;
    line-height: 1.2;
}
.main-information a.btn-line-red {
    background: transparent;
    position: absolute;
    left: 50%;
    bottom: 50px;
    margin-left: -75px;
}
.best-product-list li {
    float: left;
    width: 340px;
    margin-left: 130px;
}
.best-product-list li:first-child {
    margin-left: 0;
}
.best-product-list li .lank {
    font-size: 1.5714em;
    padding-bottom: 8px;
}
.best-product > a {
    display: block;
    border: 1px solid #e6e6e6;
}
.best-product img {
    display: block;
    margin: 0 auto;
    width: 228px;
    height: 228px;
}
.best-product figcaption {
    margin-top: 20px;
}
.best-product figcaption .brand,
.best-product figcaption .product-name,
.best-product figcaption .saving-price {
    display: block;
    margin-bottom: 8px;
}
.best-product figcaption .brand {
    color: #999;
    height: 14px;
    overflow: hidden;
}
.best-product figcaption .product-name {
    font-weight: 700;
    font-size: 1.2143em;
    height: 18px;
    overflow: hidden;
}
.best-product figcaption .saving-price .price {
    font-size: 0.9444em;
}
.main-notice {
    position: relative;
}
.main-notice h1 {
    font-size: 1.5714em;
    margin-bottom: 30px;
}
.notice-list .title {
    width: 90%;
    float: left;
    font-size: 1.4286em;
}
.notice-list .title a {
}
.notice-list .title a:hover {
    text-decoration: none;
    color: #ff5757;
}
.notice-list .date {
    display: inline-block;
    text-align: right;
    width: 10%;
    float: right;
    font-size: 1.1429em;
    line-height: 22px;
    color: #999;
}
.main-notice .product-more {
    top: 0;
    left: 50%;
    margin-left: 616px;
}
.main-notice .product-more:after,
.main-notice .product-more:before {
    background: #999;
}
.customer-list-box {
    padding-bottom: 50px;
}
.customer-list {
    padding: 20px 0;
    border: 1px solid #e1e1e1;
}
.customer-list li {
    width: 20%;
    float: left;
}
.customer-list li a {
    display: block;
    text-align: center;
    width: 100%;
    font-size: 1.5714em;
    font-weight: 700;
    padding: 175px 0 50px;
    border-left: 1px solid #e1e1e1;
    box-sizing: border-box;
}
.customer-list li:first-child a {
    border-left: 0 none;
}
.customer-list li a:hover {
    text-decoration: none;
    color: #ff5757;
}
.customer-list li.icon-service a {
    background: url(/img/bizcon/icon-service.png) no-repeat center 47px;
}
.customer-list li.icon-refund a {
    background: url(/img/bizcon/icon-refund.png) no-repeat center 47px;
}
.customer-list li.icon-faq a {
    background: url(/img/bizcon/icon-faq.png) no-repeat center 47px;
}
.customer-list li.icon-contact a {
    background: url(/img/bizcon/icon-contact.png) no-repeat center 47px;
}
.customer-list li.icon-notice a {
    background: url(/img/bizcon/icon-notice.png) no-repeat center 47px;
}
.main-search-sort {
    width: 815px;
    margin: 30px auto 0;
}
.main-search-sort .check-box {
    margin: 14px 0 0 10px;
}
.no-result {
    font-size: 1.4286em;
    padding: 10px 0 30px;
}
/* 주문페이지 인풋박스 플레이스 홀더 관련*/
.orderInputPlaceholder:focus::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: transparent;
}
.orderInputPlaceholder:focus::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent;
}
.orderInputPlaceholder:focus:-ms-input-placeholder {
    /* IE 10+ */
    color: transparent;
}
.orderInputPlaceholder:focus:-moz-placeholder {
    /* Firefox 18- */
    color: transparent;
}

/* MY 비즈콘 */
.my-contents-box {
    padding-bottom: 60px;
}
.my-contents-box > h1 {
    font-size: 1.7143em;
    font-weight: 400;
    margin-bottom: 10px;
}
.member-level-info {
    float: left;
    width: 350px;
    box-sizing: border-box;
}
.member-level-info footer {
    padding-top: 56px;
    text-align: center;
    height: 70px;
    font-size: 1.28576em;
    letter-spacing: -1px;
    line-height: 1.4;
    border-left: 1px solid #b2b2b2;
    border-bottom: 1px solid #b2b2b2;
}
.member-level-info .member-level {
    font-weight: 700;
}
.member-level-info .member-discount {
    font-size: 1.2222em;
    color: #ff5757;
    letter-spacing: -2px;
}
.member-title {
    height: 176px;
    padding-top: 44px;
    text-align: center;
    color: #fff;
    background: #494e55;
    font-size: 1.2857em;
    line-height: 1.2;
    position: relative;
}
.member-title span {
    display: block;
}
.member-title .txt-bold {
    font-size: 1.3333em;
}
.member-title .sub {
    font-size: 0.7778em;
    margin-top: 5px;
    color: #bfc1c4;
}
.member-title .welcom,
.member-title .vip,
.member-title .family,
.member-title .unreceived {
    display: inline-block;
    width: 128px;
    height: 128px;
    line-height: 128px;
    color: #fff;
    font-size: 1.3333em;
    font-weight: 700;
    position: absolute;
    left: 50%;
    bottom: -46px;
    margin-left: -64px;
}
.member-title .welcom {
    background: url(/img/bizcon/icon-level-w.png) no-repeat 0 0;
}
.member-title .vip {
    background: url(/img/bizcon/icon-level-v.png) no-repeat 0 0;
}
.member-title .family {
    background: url(/img/bizcon/icon-level-f.png) no-repeat 0 0;
}
.member-title .unreceived {
    background: url(/img/bizcon/icon-level-n.png) no-repeat 0 0;
}
.member-level-detail,
.member-notice {
    float: left;
    width: 930px;
    height: 347px;
    border: 1px solid #b2b2b2;
    position: relative;
    box-sizing: border-box;
}
.member-level-detail {
    padding: 53px 0 0 80px;
    background: #f7f7f7 url(/img/bizcon/bg-snow.png) no-repeat 814px 38px;
}
.member-level-detail > h1 {
    font-size: 2.2857em;
    margin-bottom: 16px;
    font-weight: 400;
    letter-spacing: -1px;
}
.level-comment p {
    font-size: 1.2857em;
    line-height: 1.2;
}
.level-comment p.sub {
    font-size: 1.1429em;
}
.level-comment p > .sub {
    font-size: 0.8889em;
}
.level-comment p > .sub.type02 {
    font-size: 0.7778em;
    margin-left: 20px;
}
.member-level-detail .level-type {
    position: absolute;
    top: 107px;
    right: 117px;
}
.level-type {
    display: inline-block;
}
.level-type li {
    float: left;
    width: 90px;
    margin-left: 40px;
    font-size: 1.1429em;
    text-align: center;
}
.level-type li:first-child {
    margin-left: 0;
}
.level-type li > span {
    display: inline-block;
    width: 88px;
    height: 88px;
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
}
.level-type li.vip > span {
    background: #fff url(/img/bizcon/icon-level-01.gif) no-repeat center center;
}
.level-type li.family > span {
    background: #fff url(/img/bizcon/icon-level-02.gif) no-repeat center center;
}
.level-type li.welcome > span {
    background: #fff url(/img/bizcon/icon-level-03.gif) no-repeat center center;
}
.member-notice {
    padding: 75px 0;
    background: #f7f7f7;
}
.member-notice > h1 {
    float: left;
    width: 250px;
    padding: 85px 0 85px 90px;
    font-size: 2em;
    letter-spacing: -2px;
    border-right: 1px solid #ddd;
}
.member-notice > h1 span {
    font-weight: 400;
}
.member-notice-comment {
    float: left;
    width: 502px;
    padding: 30px 0 0 85px;
}
.member-notice-comment p {
    line-height: 1.4;
}
.member-notice-comment p em {
    font-weight: 700;
    font-size: 1.2859em;
}
.form-box {
    border: 1px solid #ccc;
    padding: 72px 67px 0;
    height: 230px;
}
.form-box .user-btn-grp {
    width: auto;
}
.form-box .btn-red {
    width: 100%;
}
.tab-style li {
    float: left;
    width: 208px;
    margin-left: -1px;
}
.tab-style li a {
    display: block;
    text-align: center;
    padding: 17px 0;
    border: 1px solid #d9d9d9;
}
.tab-style li.on a,
.tab-style li a:hover {
    color: #fff;
    background: #494e55;
}
.tab-style li a:hover {
    text-decoration: none;
}
.list-count {
    position: relative;
    /* min-height: 184px; */
}
.list-count .select2 {
    position: absolute;
    right: 0;
    bottom: 0;
}
.list-count .select-pt-top {
    position: absolute;
    right: 0;
    top: 36px;
}
.list-count-func .btn-line-pink {
    margin-left: 4px;
}
.mms-view-box.type02 {
    float: left;
    width: 311px;
    margin-left: 45px;
}
.mms-editor {
    float: right;
    width: 415px;
    padding: 130px 10px 0 0;
}
.mms-editor > h1 {
    font-size: 1.2857em;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: 1px solid #b2b2b2;
}
.mms-editor-main {
    padding: 10px 20px;
    border-bottom: 1px solid #e6e6e6;
}

/* 페이징 */
.paging {
    text-align: center;
}
.paging ul {
    display: inline-block;
    *zoom: 1;
}
.paging ul:after {
    content: "";
    display: block;
    clear: both;
}
.paging ul li {
    float: left;
    width: 25px;
    height: 25px;
    line-height: 25px;
    margin: 0 3px;
    box-sizing: border-box;
}
.paging ul .now {
    color: #ff5757;
    font-weight: 700;
}
.paging ul [class*="arrow"] {
    border: 1px solid #e6e6e6;
    text-indent: -9999em;
}
.paging ul [class*="arrow"] a {
    display: block;
}
.paging ul .arrow-prev {
    margin-right: 20px;
    background: url(/img/bizcon/icon-prev.png) no-repeat center center;
}
.paging ul .arrow-prev-double {
    background: url(/img/bizcon/icon-prev-double.png) no-repeat center center;
}
.paging ul .arrow-next {
    margin-left: 20px;
    background: url(/img/bizcon/icon-next.png) no-repeat center center;
}
.paging ul .arrow-next-double {
    background: url(/img/bizcon/icon-next-double.png) no-repeat center center;
}

/* 비즈콘샵 */
.shop-biz-product,
.shop-biz-brand {
    width: 640px;
    height: 330px;
    padding-left: 234px;
    float: left;
    color: #fff;
    line-height: 1.2;
    box-sizing: border-box;
}
.shop-biz-product > h1,
.shop-biz-brand > h1 {
    font-size: 1.7143em;
    letter-spacing: -1px;
}
.shop-biz-product {
    padding-top: 87px;
    background: url(/img/bizcon/bg-shop-01.jpg) no-repeat 0 0;
}
.shop-biz-product > p {
    margin: 15px 0;
    font-size: 1.1429em;
}
.shop-biz-brand {
    padding-top: 107px;
    background: url(/img/bizcon/bg-shop-02.jpg) no-repeat 0 0;
}
.shop-biz-brand > h1 {
    margin-bottom: 46px;
}
a.btn-direct,
a.btn-direct-red {
    width: auto;
    padding: 0 50px 0 35px;
    background: #fff;
    position: relative;
}
a.btn-direct-red {
    color: #ff5757;
}
a.btn-direct:before,
a.btn-direct:after,
a.btn-direct-red:before,
a.btn-direct-red:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
}
a.btn-direct:before,
a.btn-direct-red:before {
    width: 9px;
    height: 0;
    right: 30px;
}
a.btn-direct:before {
    border-top: 1px solid #222;
}
a.btn-direct-red:before {
    border-top: 1px solid #ff5757;
}
a.btn-direct:after,
a.btn-direct-red:after {
    width: 0;
    height: 9px;
    right: 34px;
    margin-top: -4px;
}
a.btn-direct:after {
    border-left: 1px solid #222;
}
a.btn-direct-red:after {
    border-left: 1px solid #ff5757;
}
.shop-new-product {
    width: 995px;
    float: left;
}
.shop-new-product > h1,
.shop-best-product > h1 {
    font-size: 1.5714em;
    letter-spacing: -2px;
}
.shop-new-product > h1 span,
.shop-best-product > h1 span {
    font-weight: 400;
}
.line-box {
    border: 1px solid #b2b2b2;
    text-align: center;
    padding: 10px 0 20px;
    min-height: 324px;
}
.biz-product {
    display: inline-block;
    width: 1215px;
}
.biz-product li {
    float: left;
    width: 195px;
    margin-left: 60px;
    padding: 30px 0;
}
.line-box .biz-product {
    width: auto;
}
.line-box .biz-product li {
    margin-left: 40px;
}
.biz-product li:nth-child(5n + 1) {
    margin-left: 0;
}
.biz-product figure > a {
    display: block;
    height: 145px;
    text-align: center;
}
.biz-product figure img,
.shop-best-top img {
    width: 145px;
    height: 145px;
}
.biz-product figcaption {
    margin-top: 20px;
    text-align: center;
}
.biz-product .brand,
.biz-product .product-name {
    display: block;
}
.biz-product .brand,
.biz-product .price {
    color: #999;
}
.biz-product .brand {
    height: 14px;
    overflow: hidden;
}
.biz-product .product-name {
    font-weight: 700;
    padding-top: 7px;
    height: 38px;
    overflow: hidden;
    font-size: 1.1429em;
    line-height: 1.2;
}
.biz-product .cover-price {
    display: block;
    border-top: 1px solid #ccc;
    margin-top: 9px;
    padding-top: 10px;
}
.shop-best-product {
    width: 263px;
    min-height: 354px;
    float: right;
    border: 1px solid #b2b2b2;
    margin-top: 31px;
}
.shop-best-product > h1 {
    padding: 20px 20px 15px;
}
.shop-best-top > a {
    display: block;
    text-align: center;
}
.shop-best-top figcaption,
.best-lanking-list li {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
}
.shop-best-top figcaption {
    padding: 0 18px 14px;
}
.shop-best-top .lank {
    font-size: 1.5714em;
    font-weight: 700;
    letter-spacing: -1px;
}
.shop-best-top .lank em {
    color: #ff5757;
    margin-right: 4px;
}
.shop-best-top .product-name {
    font-weight: 700;
    letter-spacing: -1px;
}
.best-lanking-list {
    background: #494e55;
    padding: 20px;
    box-sizing: border-box;
    height: 116px;
}
.best-lanking-list li {
    color: #fff;
}
.best-lanking-list li a {
    color: #fff;
    line-height: 1.4;
}
.bold-line-box {
    border-top: 2px solid #494e55;
    border-bottom: 1px solid #e6e6e6;
    text-align: center;
}
.shop-contents {
    position: relative;
}
.shop-contents .total {
    font-size: 1.5714em;
    letter-spacing: -1px;
}
.shop-contents .total span {
    color: #ff5757;
}
.sort-type {
    position: absolute;
    right: 0;
    top: 12px;
}
.sort-type li {
    float: left;
    padding: 0 20px;
    border-left: 1px solid #ddd;
}
.sort-type li:first-child {
    border-left: 0 none;
}
.sort-type li:last-child {
    padding-right: 0;
}
.sort-type li button {
    color: #b3b3b3;
}
.sort-type li.on button {
    color: #ff5757;
    border-bottom: 1px solid #ff5757;
}
.shop-contents-box {
    border-top: 2px solid #494e55;
    padding-top: 20px;
    text-align: center;
}
.more-product-view {
    width: 310px;
    text-align: center;
    padding: 18px 0;
    background: #f8f8f8;
    border: 1px solid #ddd;
}
.shop-search-box {
    position: relative;
}
.shop-search-box input[type="text"] {
    padding-right: 40px;
}
.shop-search-box .btn-search {
    width: 38px;
    height: 28px;
    border-left: 1px solid #ccc;
    background: #fff url(/img/bizcon/icon-search.png) no-repeat center center;
    text-indent: -9999em;
    position: absolute;
    right: 1px;
    top: 6px;
}
#shop-category {
    margin: 10px 0;
    /* min-height: 84px; */
    background: url(/img/bizcon/bg-line-y.gif) repeat-y 190px 0;
}
.brand-logo {
    width: 354px;
    height: 105px;
    padding: 4px 0;
    border: 1px solid #ccc;
    text-align: center;
    position: absolute;
    top: 48px;
    left: 50%;
    margin-left: -178px;
}
.brand-logo img {
    width: 185px;
    height: 105px;
}
.biz-brand {
    display: inline-block;
    width: 1220px;
}
.biz-brand li {
    float: left;
    width: 195px;
    height: 141px;
    margin-left: 10px;
    padding: 10px 0 20px;
}
.biz-brand li:nth-child(6n + 2) {
    margin-left: 0;
}
.biz-brand figure a {
    display: block;
    border: 5px solid #e6e6e6;
}
.biz-brand figure img {
    width: 185px;
    height: 105px;
    display: block;
}
.biz-brand figure figcaption {
    margin-top: 10px;
    font-size: 1.1429em;
}
.search-box {
    width: 670px;
    margin: 0 auto;
}
.search-box input[type="text"] {
    float: left;
    height: 54px;
    line-height: 54px;
    font-size: 1.4286em;
    border: 3px solid #3b3a40;
    border-right: 0 none;
}
.search-box button {
    float: left;
    height: 60px;
    line-height: 60px;
    width: 120px;
    font-size: 1.4286em;
    font-weight: 400;
    border-radius: 0;
    color: #fff;
    background: #3b3a40;
}
.search-box.type02 {
    width: 916px;
}
.search-box.type02 input[type="text"] {
    height: 64px;
    line-height: 64px;
    border: 3px solid #ff5757;
    border-right: 0 none;
    border-left: 0 none;
}
.search-box.type02 label,
.search-box.type02 button {
    height: 70px;
    line-height: 70px;
    width: 168px;
}
.search-box.type02 label {
    float: left;
    display: inline-block;
    color: #fff;
    font-size: 1.4286em;
    padding-left: 78px;
    background: #ff5757;
    box-sizing: border-box;
}
.search-box.type02 button {
    text-indent: -9999em;
    background: #ff5757 url(/img/bizcon/icon-search-red.gif) no-repeat 30px center;
}
.tb-type-box {
    border: 1px solid #e6e6e6;
    position: relative;
}
.tb-type-box > h1 {
    float: left;
    width: 190px;
    height: 123px;
    line-height: 123px;
    font-size: 1.5714em;
    text-align: center;
    letter-spacing: -2px;
    background: #f7f7f7;
}
.tb-type-box > div {
    float: left;
    width: 1038px;
    height: 93px;
    padding: 30px 0 0 50px;
}
.tb-type-box .btn-list.type03 {
    width: 780px;
}
.all-download {
    padding: 0 0 3px 18px;
    border-bottom: 1px solid #222;
    background: url(/img/bizcon/icon-download.png) no-repeat 0 0;
    position: absolute;
    right: 30px;
    bottom: 20px;
}
.all-download-excel {
    padding: 0 0 3px 18px;
    border-bottom: 1px solid #222;
    background: url(/img/bizcon/icon-download.png) no-repeat 0 0;
    right: 30px;
    bottom: 20px;
}
.product-order-info {
    padding: 33px 82px 40px;
    border-top: 1px solid #494e55;
    border-bottom: 1px dashed #c0c0c0;
}
.product-order-info > h1 {
    font-size: 1.5714em;
    margin-bottom: 10px;
}
.product-info .product-img {
    width: 400px;
    float: left;
}
.product-info .product-img img {
    display: block;
    width: 400px;
    height: 400px;
    /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1); */
}
.product-info .product-price-info {
    width: 620px;
    float: right;
}
.product-price-info > h1 {
    font-size: 1.5714em;
    padding-top: 20px;
    min-height: 42px;
    line-height: 1.1;
}
.product-price-info .cover-price {
    font-size: 1em;
    font-weight: 400;
}
.product-price-info .cover-price .cover-rate {
    color: #ff5757;
}
.product-price-info .cover-price .won {
    font-size: 1em;
    font-weight: 700;
    top: 0;
}
.product-price-info > ul > li {
    margin-top: 30px;
    *zoom: 1;
}
.product-price-info > ul > li:after {
    content: "";
    display: block;
    clear: both;
}
.product-price-info > ul > li > h1,
.product-price-info > ul > li > div {
    float: left;
    box-sizing: border-box;
}
.product-price-info > ul > li > h1 {
    width: 15%;
    font-size: 1em;
}
.product-price-info > ul > li > .line-interval {
    line-height: 160%;
}
.product-price-info > ul > li > div {
    width: 85%;
}
.level-price {
    position: relative;
}
.level-price .tooltip-box {
    position: absolute;
    right: 0;
    top: -34px;
}
.level-price .tooltip-box > span {
    font-weight: 700;
}
.level-price .tooltip-box .tooltip {
    right: 0;
    left: auto;
}
.level-price-detail li {
    float: left;
    width: 33.3333%;
    text-align: center;
    border-top: 1px solid #b2b2b2;
    border-bottom: 1px solid #b2b2b2;
    box-sizing: border-box;
}
.level-price-detail li > h1,
.level-price-detail li > div {
    height: 54px;
    line-height: 54px;
}
.level-price-detail li > h1 {
    font-size: 1em;
    background: #f7f7f7;
    border-bottom: 1px solid #e3e4e5;
}
.level-price-detail li > div {
    background: #fff;
}
.level-price-detail .cover-price .won {
    border: 1px solid #c6c6c6;
    background: #d7d7d7;
}
.level-price-detail .cover-price .price {
    font-weight: bold;
}
.level-price-detail li.on {
    border-left: 3px solid #ff5757;
    border-right: 3px solid #ff5757;
}
.level-price-detail li.on > h1,
.level-price-detail li.on > div {
    height: 51px;
    line-height: 51px;
}
.level-price-detail li.on > h1 {
    border-top: 3px solid #ff5757;
    color: #ff5757;
}
.level-price-detail li.on > div {
    border-bottom: 3px solid #ff5757;
}
.level-price-detail li.on .cover-price .price {
    color: #ff5757;
    font-weight: 700;
}
.level-price-detail li.on .cover-price .won {
    border: 1px solid #df423d;
    background: #ff6762;
}
.product-detail {
    margin-top: 30px;
    padding: 0 10px;
    line-height: 1.4;
    font-size: 1.1429em;
}
.product-detail > h1 {
    font-size: 1.375em;
    font-weight: 400;
    padding-bottom: 20px;
}
.product-detail .main-contents {
    padding-bottom: 30px;
}
.product-detail .main-contents.img {
    text-align: center;
    padding-bottom: 0;
}

/* 고객센터 */
.cs-header {
    padding-bottom: 20px;
    position: relative;
}
.cs-header > h1 {
    width: 80%;
    font-size: 1.5714em;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 1.2;
}
.cs-header > ul {
    position: absolute;
    right: 0;
    bottom: 10px;
}
.cs-header > ul li {
    float: left;
    color: #999;
    margin-left: 20px;
}
.cs-header .sub {
    font-size: 1.1429em;
    margin: 15px 0 -10px;
}
.cs-header .date {
    padding-left: 20px;
    background: url(/img/bizcon/icon-date.png) no-repeat 0 center;
}
.cs-header .view {
    padding-left: 20px;
    background: url(/img/bizcon/icon-view.png) no-repeat 0 center;
}
.cs-header.type02 > h1 {
    width: 75%;
}
.cs-header.type02 .date,
.cs-header.type02 .view {
    padding-left: 0;
    background: none;
}
.cs-main {
    padding: 30px;
    margin-bottom: 20px;
    line-height: 1.4;
    min-height: 320px;
    border-top: 2px solid #494e55;
    border-bottom: 1px solid #494e55;
}
.cs-main img {
    max-width: 100%;
}
.cs-main.type02 {
    padding: 0;
    min-height: auto;
}
.cs-main table {
    line-height: 0;
}
.notice-prev {
    border-bottom: 1px solid #d9d9d9;
}
.notice-next {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #f2f2f2;
}
.notice-prev > h1,
.notice-next > h1,
.notice-prev > p,
.notice-next > p {
    float: left;
    height: 50px;
    line-height: 50px;
}
.notice-prev > h1,
.notice-next > h1 {
    padding-left: 60px;
    width: 60px;
    font-weight: 400;
    font-size: 1em;
}
.notice-prev > h1 {
    background: url(/img/bizcon/arrow-prev-line.gif) no-repeat 30px center;
}
.notice-next > h1 {
    background: url(/img/bizcon/arrow-next-line.gif) no-repeat 30px center;
}
.notice-prev > p,
.notice-next > p {
    width: 1100px;
    padding-right: 60px;
}
.contact-main {
    padding: 50px 50px 50px 250px;
    position: relative;
}
.contact-main.answer {
    background: #fcfcfc;
    border-top: 1px solid #c2c4c6;
}
.contact-main > h1 {
    font-size: 1.4286em;
    font-weight: 800;
    width: 57px;
    float: left;
}
.contact-main.question > h1 {
    color: #494e55;
}
.contact-main.answer > h1 {
    color: #ff5757;
}
.contact-main .category,
.contact-main .state {
    font-weight: 400;
    font-size: 1.1429em;
    width: 200px;
    position: absolute;
    top: 30px;
    left: 27px;
}
.category-sort {
    width: 780px;
    margin: 20px auto 0;
}
.category-sort li {
    float: left;
    width: 130px;
    box-sizing: border-box;
}
.category-sort li button {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    font-weight: 700;
    border-left: 1px solid #e6e6e6;
}
.category-sort li:first-child button {
    border-left: 0 none;
}
.category-sort li.on button {
    color: #ff5757;
}
.faq-list {
    border-top: 2px solid #494e55;
}
.faq-list li {
    border-bottom: 1px solid #e6e6e6;
}
.faq-header {
    width: 100%;
}
.faq-header > span {
    display: block;
    float: left;
    padding: 23px 0;
}
.faq-header .no {
    width: 100px;
}
.faq-header .category {
    width: 100px;
}
.faq-header .title {
    width: 1070px;
    text-align: left;
    background: url(/img/bizcon/arrow-open.gif) no-repeat 1035px center;
}
.faq-header .title > span,
.faq-header .title > em {
    display: inline-block;
}
.faq-header .title > span {
    color: #d0cfcf;
    font-weight: 800;
    vertical-align: top;
    width: 90px;
    text-align: center;
}
.faq-header .title > em {
    width: 900px;
    line-height: 1.2;
}
.faq-contents {
    border-top: 1px solid #e6e6e6;
    background: #fcfcfc;
    padding: 30px 0;
    display: none;
}
.faq-contents > h1 {
    margin-left: 200px;
    width: 90px;
    text-align: center;
    font-weight: 800;
    font-size: 1em;
    color: #ff5757;
    float: left;
}
.faq-contents > p {
    float: left;
    width: 900px;
    line-height: 1.4;
}
.faq-list li.on .faq-header .title {
    background: url(/img/bizcon/arrow-close.gif) no-repeat 1035px center;
}
.faq-list li.on .faq-header .title > span {
    color: #494e55;
}
.faq-list li.on .faq-header .title > em {
    font-weight: 700;
}
.faq-list li.on .faq-contents {
    display: block;
}

/* 사이트맵 */
.my-contents-box {
    padding-bottom: 60px;
}
.sitemap {
    border: 1px solid #ddd;
    padding: 90px 0 90px 90px;
    text-align: center;
}
.sitemap > ul {
    display: inline-block;
    width: 1200px;
}
.sitemap > ul > li {
    float: left;
    width: 200px;
    text-align: left;
}
.sitemap > ul > li a {
    display: inline-block;
}
.sitemap > ul > li > a {
    font-weight: 700;
    font-size: 1.2857em;
}
.sitemap > ul > li > ul > li > a {
    font-weight: 700;
    margin-top: 30px;
}
.sitemap > ul > li > ul > li > ul > li > a {
    margin: 10px 0 0 10px;
    padding-left: 7px;
    background: url(/img/bizcon/bullet-black.gif) no-repeat 0 center;
}

/* 에러페이지 */
.error {
    width: 1024px;
    height: 830px;
    padding-bottom: 120px;
    margin: 0 auto;
    text-align: center;
    letter-spacing: -1px;
    position: relative;
}
.error > h1 {
    padding: 14px 0;
    border-bottom: 3px solid #3b3b3d;
}
.error > h1 img {
    width: 110px;
    height: 56px;
}
.error > section > h1 {
    padding: 110px 0 70px;
    font-size: 2.5em;
}
.error > section .txt01 {
    margin-top: 34px;
    font-size: 1.5714em;
}
.error > section .txt02 {
    margin-top: 10px;
    font-size: 1.1429em;
    line-height: 1.4;
}
.error > footer {
    width: 100%;
    border-top: 1px solid #a8a8a8;
    padding: 20px 0;
    position: absolute;
    bottom: 0;
    left: 0;
}
.error > footer address {
    color: #acacac;
    font-size: 0.8571em;
    padding-bottom: 30px;
    line-height: 1.4;
}

/* 2019-01-02 비즈콘 소개(개편삭제)
.about-contents {
    margin-top: 50px;
}
.about-contents > h1 {
    font-size: 1.5714em;
    letter-spacing: -1px;
}
.about-contents .tit, .about-contents .comment {
    letter-spacing: -1px;
    text-align: center;
}
.about-contents .tit {
    font-weight: 700;
    font-size: 1.5714em;
    line-height: 1.2;
}
.about-contents .comment {
    line-height: 1.4;
}
.number-contents-list > li {
    margin-top: 40px;
    position: relative;
}
.number-contents-list > li:first-child {
    margin-top: 0;
}
.number-contents-list > li > h1 {
    font-size: 1.1429em;
    margin-bottom: 10px;
}
.number-contents-list > li > div {
    margin-left: 30px;
    line-height: 1.4;
    letter-spacing: -1px;
}
.ui-process > h1 {
    margin-bottom: 10px;
}
.ui-process ul li {
    float: left;
    padding-left:20px;
}
.ui-process ul li:first-child {
    padding-left: 0;
}
.ui-process ul li span {
    margin-left: 20px;
}
.ui-process.type02 > h1 {
    color: #ff5757;
}
.ui-process.type02 ul li {
    padding: 10px 0 0;
}
.ui-process.type02 ul li > p {
    padding: 108px 10px 0;
    float: left;
}
.ui-process.type02 ul li.icon01 > p {
    background: url(/img/bizcon/icon-process-01.gif) no-repeat 54px 0;
}
.ui-process.type02 ul li.icon02 > p {
    background: url(/img/bizcon/icon-process-02.gif) no-repeat 68px 0;
}
.ui-process.type02 ul li.icon03 > p {
    background: url(/img/bizcon/icon-process-03.gif) no-repeat 64px 0;
}
.ui-process.type02 ul li > span {
    display: block;
    float: left;
    padding: 40px 20px 0;
}
.alliance > h1 {
    font-size: 1.5714em;
    font-weight: 400;
    letter-spacing: -1px;
    padding-bottom: 20px;
    border-bottom: 2px solid #494e55;
}
.brand-category {
    padding: 20px 18px 0;
}
.brand-category > li {
    margin-top: 10px;
    *zoom: 1;
}
.brand-category > li:after {
    content: "";
    display: block;
    clear: both;
}
.brand-category > li:first-child {
    margin-top: 0;
}
.brand-category > li > h1, .brand-category > li > ul, .brand-category > li > ul > li {
    float: left;
}
.brand-category > li > h1, .brand-category > li > ul > li {
    height: 105px;
    border: 5px solid #e6e6e6;
}
.brand-category > li > h1 {
    width: 200px;
    line-height: 105px;
    text-align: center;
    font-size: 1.1429em;
}
.brand-category > li > ul > li {
    width: 155px;
    margin-left: -5px;
}*/
/* 2018-12-28 이용안내(개편삭제)
.use-contents {
    border: 1px solid #c9cbcc;
}
.tit-nav li {
    float: left;
    width: 25%;
    background: #f8f8f8;
    position: relative;
}
.tit-nav li a {
    display: block;
    text-align: center;
    font-weight: 700;
    padding: 16px 0;
    margin: 5px 0;
    border-left: 1px solid #c9cbcc;
}
.tit-nav li:first-child a {
    border-left: 0 none;
}
.tit-nav li a:hover {
    text-decoration: none;
}
.tit-nav li.on a {
    color: #fff;
}
.tit-nav li.on:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -6px;
}
#useFirst .tit-nav li.on {
    background: #494e55;
}
#useFirst .tit-nav li.on:after {
    border-top: 5px solid #494e55;
}
#useSecond .tit-nav li.on {
    background: #ff5757;
}
#useSecond .tit-nav li.on:after {
    border-top: 5px solid #ff5757;
}
#useThird .tit-nav li.on {
    background: #6358e9;
}
#useThird .tit-nav li.on:after {
    border-top: 5px solid #6358e9;
}
#useFourth .tit-nav li.on {
    background: #ffb71c;
}
#useFourth .tit-nav li.on:after {
    border-top: 5px solid #ffb71c;
}
.use-contents-main {
    letter-spacing: -1px;
    line-height: 1.6;
    font-size: 1.1429em;
    position: relative;
}
#useFirst .use-contents-main {
    padding: 60px 100px 60px 230px;
}
#useFirst .use-contents-main > h1 {
    font-size: 1.375em;
    position: absolute;
    left: 100px;
    top: 55px;
}
#useFirst .use-contents-main section > h1 {
    font-size: 1em;
}
#useSecond .use-contents-main {
    padding: 60px 150px;
}
#useSecond .use-contents-main li {
    margin-top: 20px;
}
#useSecond .use-contents-main li:first-child {
    margin-top: 0;
}
#useSecond .use-contents-main li > h1 {
    font-size: 1em;
    margin-bottom: 20px;
}
#useSecond .use-contents-main li > h1 > span {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-size: 0.875em;
    border-radius: 50%;
    background: #494e55;
    margin-right: 48px;
}
#useSecond .use-contents-main li.process01 {
    height: 479px;
    background: url(/img/bizcon/img-use-process-01.gif) no-repeat 100% 0;
}
#useSecond .use-contents-main li.process02 {
    height: 479px;
    background: url(/img/bizcon/img-use-process-02.gif) no-repeat 100% 0;
}
#useSecond .use-contents-main li.process03 {
    height: 479px;
    background: url(/img/bizcon/img-use-process-03.gif) no-repeat 100% 0;
}
#useSecond .use-contents-main li.process04 {
    height: 319px;
    background: url(/img/bizcon/img-use-process-04.gif) no-repeat 100% 0;
}
#useSecond .use-contents-main li > div {
    margin-left: 78px;
    padding-bottom: 98px;
    background: url(/img/bizcon/bg-arrow.gif) no-repeat 170px 100%;
}
#useSecond .use-contents-main li.process04 > div {
    background: none;
}
#useThird .use-contents-main {
    padding: 0 50px;
}
#useThird .use-contents-main li {
    padding: 60px 60px 50px;
    border-top: 1px dashed #c0c0c0;
}
#useThird .use-contents-main li:first-child {
    border-top: 0 none;
}
#useThird .use-contents-main li.benefit01 {
    background: url(/img/bizcon/bg-confirm-clear.png) no-repeat 174px 60px;
}
#useThird .use-contents-main li.benefit02 {
    background: url(/img/bizcon/bg-confirm-fail.png) no-repeat 174px 60px;
}
#useThird .use-contents-main li > h1 {
    font-size: 1em;
    margin: 0 0 20px;
}
#useThird .use-contents-main li > h1 > span {
    display: inline-block;
    color: #e1e1e1;
    font-weight: 400;
    font-size: 1.625em;
    margin-right: 378px;
}
#useThird .use-contents-main li > div {
    margin-left: 407px;
}
#useThird .use-contents-main li > div a {
    display: inline-block;
    margin-top: 40px;
    color: #ff5757;
    font-weight: 700;
    line-height: 1.2;
    border-bottom: 1px solid #ff5757;
}
#useThird .use-contents-main li > div a:hover {
    text-decoration: none;
}
#useFourth .use-contents-main {
    padding: 60px 90px;
}
#useFourth .use-contents-main li {
    width: 50%;
    float: left;
    padding: 30px 40px 30px 130px;
    box-sizing: border-box;
}
#useFourth .use-contents-main li.service01 {
    background: url(/img/bizcon/icon-service-01.gif) no-repeat 0 22px;
}
#useFourth .use-contents-main li.service02 {
    background: url(/img/bizcon/icon-service-02.gif) no-repeat 0 22px;
}
#useFourth .use-contents-main li.service03 {
    background: url(/img/bizcon/icon-service-03.gif) no-repeat 0 22px;
}
#useFourth .use-contents-main li.service04 {
    background: url(/img/bizcon/icon-process-02.gif) no-repeat 10px 22px;
}
#useFourth .use-contents-main li > h1 {
    font-size: 1em;
}
#useFourth .use-contents-main li > div {
    font-size: 0.875em;
}*/
.brand-category > li > ul > li img {
    width: 155px;
    height: 105px;
}
.user-info-header {
    height: 156px;
    text-align: center;
    background: url(/img/bizcon/bg-present.png) no-repeat 88% 100%;
    position: relative;
}
.user-info-header h1 {
    position: absolute;
    top: 32px;
    left: 50%;
    margin-left: -156px;
}
.user-info-header .sub-comment {
    font-size: 1.1429em;
    line-height: 1.2;
}
.user-info-header .sub-comment p:first-child {
    margin-bottom: 100px;
}
.user-info-section {
    padding: 64px 0 70px;
}
.user-info-section ul li {
    float: left;
    width: 33.3333%;
    font-size: 1.1429em;
    text-align: center;
    padding-top: 50px;
    position: relative;
}
.user-info-section ul li.vip {
    background: url(/img/bizcon/img-level-01.gif) no-repeat center 110px;
}
.user-info-section ul li.family {
    background: url(/img/bizcon/img-level-02.gif) no-repeat center 110px;
}
.user-info-section ul li.welcome {
    background: url(/img/bizcon/img-level-03.gif) no-repeat center 115px;
}
.user-info-section ul li > h1 {
    font-size: 2em;
    font-weight: 400;
    letter-spacing: -2px;
    color: #6c6c6c;
}
.user-info-section .user-discount {
    width: 100%;
    font-size: 2.8125em;
    font-weight: 700;
    letter-spacing: -3px;
    margin-bottom: 150px;
    position: absolute;
    top: 0;
    left: 0;
}
.user-info-section .user-discount span {
    color: #ff5757;
    margin-right: 5px;
}
.user-info-section .user-discount + p {
    margin-top: 150px;
    line-height: 1.2;
}
.user-info-footer {
    border-top: 1px dashed #c0c0c0;
    padding: 30px 30px 0;
}
.user-info-footer > h1 {
    font-size: 1.1429em;
    margin-bottom: 20px;
}
.biz-case-list {
    display: inline-block;
    width: 1170px;
}
.biz-case-list li {
    float: left;
    width: 350px;
    margin: 20px 0 0 60px;
}
.biz-case-list li:nth-child(3n + 1) {
    margin-left: 0;
}
.biz-case-list figure a,
.biz-case-list figure img {
    display: block;
}
.biz-case-list figure img,
.biz-event-list figure img {
    width: 350px;
    height: 180px;
}
.biz-case-list figcaption a {
    width: 100%;
    font-size: 1.1429em;
    padding: 20px 0;
    text-align: left;
}
.biz-case-list figcaption .brand {
    font-weight: 700;
    margin-right: 5px;
}
.biz-event-list li {
    float: left;
    width: 50%;
    height: 180px;
    padding: 0 30px;
    margin-bottom: 30px;
    box-sizing: border-box;
    border-right: 1px solid #eee;
    position: relative;
}
.biz-event-list li:nth-child(2n) {
    border-right: 0 none;
}
.biz-event-list figure > a,
.biz-event-list .event-name,
.biz-event-list .event-tit,
.biz-event-list .event-state,
.biz-event-list .event-date {
    display: block;
}
.biz-event-list figure > a,
.biz-event-list figcaption {
    float: left;
}
.biz-event-list figcaption {
    width: 209px;
    padding: 25px 0 0 20px;
    text-align: left;
    line-height: 1.2;
}
.biz-event-list figcaption > div {
    position: absolute;
    left: 400px;
    bottom: 25px;
}
.biz-event-list .event-name {
    font-weight: 700;
}
.biz-event-list .event-tit,
.biz-event-list .event-date {
    margin-top: 5px;
}
.biz-event-list .close-bg {
    width: 380px;
    height: 180px;
    background: url(/img/bizcon/bg-event-close.png) no-repeat 0 0;
    position: absolute;
    top: 0;
    left: 30px;
}

.product-img-wrap .tooltip {
    width: 450px;
    top: 30px;
    left: 0;
}
.product-img-wrap .tooltip b {
    font-size: 1.1em;
    line-height: 1.1em;
    margin-bottom: 13px;
    display: inline-block;
}
.product-img-wrap .tooltip ul li {
    font-size: 1em;
    margin-bottom: 7px;
}
.product-img-wrap .tooltip .btn-img-down {
    margin-top: 30px;
    font-weight: 700;
}
.product-img-wrap .tooltip .btn-img-down a {
    border-bottom: 1px solid #222;
    padding-bottom: 3px;
}

/* 2018-06-28 기업문의 */
.box-list-bullet-effect {
    padding: 23px 0 27px 0;
    border: 1px solid #ddd;
}
.box-list-bullet-effect::after {
    content: "";
    display: block;
    clear: both;
}
.box-list-bullet-effect li {
    position: relative;
    float: left;
    width: 360px;
    height: 135px;
    font-size: 14px;
    line-height: 1.6;
    padding: 10px 20px 0 15px;
    border-right: 1px solid #e0e0e0;
    background: url(/img/bizcon/bullet-red-middle.png) no-repeat 0 17px;
    margin-left: 30px;
    letter-spacing: -0.05em;
    word-break: keep-all;
}
.box-list-bullet-effect li:last-child {
    border-right: 0;
}
.box-list-bullet-effect li .tit {
    font-size: 16px;
    font-weight: 700;
}
.box-list-bullet-effect li::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -2px;
    width: 78px;
    height: 70px;
    background-image: url(/img/bizcon/bg-info-step.jpg);
    background-repeat: no-repeat;
}
.box-list-bullet-effect li + li::after {
    bottom: -2px;
    width: 82px;
    background-position: -99px 0;
}
.box-list-bullet-effect li + li + li::after {
    right: -1px;
    bottom: -2px;
    width: 82px;
    background-position: -201px 0;
}

.ent-contents-box {
    padding-bottom: 60px;
}
.ent-contents-box .signUp-box-contents {
    margin-top: 50px;
}
.ent-contents-box .signUp-box-contents > h1 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.025em;
    margin-bottom: 20px;
}

/* 2018-06-28 대량발송 */
.agree-box {
    height: 200px;
    border: 1px solid #ddd;
    position: relative;
}

.box-list-bullet-step {
    padding: 60px 0 45px 0;
    border: 1px solid #ddd;
}
.box-list-bullet-step::after {
    content: "";
    display: block;
    clear: both;
}
.box-list-bullet-step li {
    position: relative;
    float: left;
    width: 195px;
    height: 135px;
    font-size: 14px;
    line-height: 1.6;
    padding-left: 15px;
    background: url(/img/bizcon/bullet-red-middle.png) no-repeat 0 7px;
    letter-spacing: -0.05em;
    word-break: keep-all;
    margin-left: 41px;
}
.box-list-bullet-step li:last-child {
    width: 195px;
}
.box-list-bullet-step li .tit {
    font-size: 16px;
    font-weight: 700;
}
.box-list-bullet-step li .step {
    font-size: 14px;
    font-weight: 700;
    color: #ff5757;
    position: absolute;
    left: 0;
    top: -18px;
}
.box-list-bullet-step li::after {
    content: "";
    width: 10px;
    height: 22px;
    background-image: url(/img/bizcon/ico-step-arrow.jpg);
    background-repeat: no-repeat;
    position: absolute;
    top: -9px;
    right: -15px;
}
.box-list-bullet-step li:last-child::after {
    content: none;
}

.lrgsend-contents-box {
    padding-bottom: 60px;
}
.lrgsend-contents-box .signUp-box-contents {
    margin-top: 50px;
}
.lrgsend-contents-box .signUp-box-contents > h1 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.025em;
    margin-bottom: 20px;
}

.lrgsend-contents-box .sub-desc {
    font-size: 18px;
    letter-spacing: -0.05em;
    margin-bottom: 15px;
}
.lrgsend-contents-box .dot-list li {
    font-size: 16px;
    letter-spacing: -0.05em;
    margin-bottom: 5px;
}

/* 2018-06-28 제휴/프로모션 */
.list-img-txt {
    letter-spacing: -1px;
    line-height: 1.6;
    font-size: 1.1429em;
    position: relative;
}

.list-img-txt {
    border: 1px solid #cdcdcd;
    padding: 60px;
}
.list-img-txt::after {
    content: "";
    display: block;
    clear: both;
}

.list-img-txt li {
    min-height: 95px;
    padding-left: 130px;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 60px;
}
.list-img-txt ul {
    margin-top: -60px;
    margin-left: -60px;
}
.list-img-txt ul li.service01 {
    background: url(/img/bizcon/ico-proEvent-01.jpg) no-repeat;
}
.list-img-txt ul li.service02 {
    background: url(/img/bizcon/ico-proEvent-02.jpg) no-repeat;
}
.list-img-txt ul li.service03 {
    background: url(/img/bizcon/ico-proEvent-03.jpg) no-repeat;
}
.list-img-txt ul li.service04 {
    background: url(/img/bizcon/ico-proEvent-04.jpg) no-repeat;
}
.list-img-txt ul li > h1 {
    font-size: 1em;
}
.list-img-txt li > h1 {
    font-size: 1em;
}
.list-img-txt li .tit {
    display: block;
    color: #ff5757;
}
.list-img-txt li > div {
    font-size: 0.875em;
}
.list-img-txt.count2 li {
    float: left;
    width: 549px;
    margin-left: 60px;
}

.promotion-contents-box {
    padding-bottom: 60px;
}
.promotion-contents-box .signUp-box-contents {
    margin-top: 50px;
}
.promotion-contents-box .signUp-box-contents > h1 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.025em;
    margin-bottom: 20px;
}

/* 2018-12-28 : 비즈콘소개, 이용안내 비쥬얼 이미지 */
#contents-visual {
    width: 100%;
    text-align: center;
}
#contents-visual img {
    vertical-align: top;
}
.visual-use {
    background-color: #f0f1f2;
}
.visual-about {
    background-color: #fdfae2;
}

/* 이용안내 2020-09-17 */
#contents-main.use {
    width: 1280px;
}

.use-contents {
    margin-bottom: 150px;
}
.use-contents.last {
    margin-bottom: 0;
}
.use-contents h1 {
    margin-bottom: 60px;
    padding: 22px 0;
    background: #01b369;
    font-weight: 600;
    font-size: 26px;
    letter-spacing: -0.025em;
    color: #fff;
    text-align: center;
}
.use-contents h1 > span {
    font-weight: 800;
}
.use-contents-desc {
    margin-bottom: 15px;
    padding-left: 91px;
    font-size: 18px;
    color: #2f2f2f;
    line-height: 1.6em;
    letter-spacing: -0.025em;
}
.use-contents-main .pos-al-right {
    padding-left: 272px;
    font-size: 16px;
    letter-spacing: -1px;
    color: #000;
}
.use-contents-main .pos-al-right u {
    text-decoration: none;
    border-bottom: 1px solid #000;
}
.black-bullet-list {
    position: relative;
}
.black-bullet-list::before {
    content: "";
    position: absolute;
    left: -7px;
    top: 14px;
    width: 3px;
    height: 3px;
    border-radius: 6px;
    background-color: #2f2f2f;
}

.black-hyphen-list {
    position: relative;
    padding-left: 10px;
}
.black-hyphen-list::before {
    content: "";
    position: absolute;
    left: 0;
    top: 14px;
    width: 4px;
    height: 1px;
    background-color: #2f2f2f;
}

/* 비즈콘 이란? */
.about-sec1 {
    width: 1040px;
    margin: 110px auto;
    padding: 17px 120px;
    background: url(/img/bizcon/bg-about-bizcon.jpg) no-repeat 835px 22px;
}
.about-contents .about-contents-desc {
    margin-top: 35px;
    font-size: 17px;
    color: #2f2f2f;
    line-height: 1.7em;
    letter-spacing: -0.025em;
}

.about-sec2 {
    background: #f3f4f6;
    min-height: 544px;
    overflow: hidden;
}
.about-sec2 .about-contents-header {
    width: 1280px;
    margin: 0 auto;
}
.about-sec2 .about-contents-header h1 {
    padding: 0 120px;
    margin: 60px 0 30px;
    display: inline-block;
    font-size: 30px;
    letter-spacing: -0.025em;
}
.about-sec2 .about-contents-main {
    position: relative;
    width: 1037px;
    height: 352px;
    margin: 0 auto;
    border: 1px solid #e1e1e1;
}

#serviceSlider {
    overflow: hidden;
    width: 1037px;
}
.about-contents-main .bx-wrapper img {
    width: 100%;
    height: 352px;
    display: block;
}
.about-contents-main .bx-wrapper .bx-controls-direction a {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    outline: 0;
    width: 35px;
    height: 81px;
    text-indent: -999999px;
    z-index: 9;
    overflow: hidden;
}
.about-contents-main .bx-wrapper .bx-controls-direction .bx-prev {
    left: -65px;
    background: url(/img/bizcon/btn-about-prev.png) no-repeat 0 0;
}
.about-contents-main .bx-wrapper .bx-controls-direction .bx-next {
    right: -65px;
    background: url(/img/bizcon/btn-about-next.png) no-repeat 0 0;
}
.about-contents-main .bx-wrapper .bx-pager {
    display: inline-block;
    position: absolute;
    bottom: 368px;
}
.about-contents-main .bx-wrapper .bx-pager {
    left: 977px;
}
.about-contents-main .bx-wrapper .bx-pager.bx-default-pager a {
    background: #dcdcdc;
    border: none;
    text-indent: -9999em;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 0 0 10px;
    outline: 0;
    border-radius: 50%;
}
.about-contents-main .bx-wrapper .bx-pager.bx-default-pager a:hover,
.about-contents-main .bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #969696;
    border: none;
}

.about-sec3 {
    width: 1280px;
    margin: 100px auto 0;
}
.about-sec3 h1 {
    text-align: center;
    font-size: 50px;
    color: #2f2f2f;
    line-height: 1em;
    letter-spacing: -0.025em;
}
.about-sec3 .about-contents-desc {
    margin: 40px 0 65px;
    text-align: center;
}
.about-contents-main {
    text-align: center;
}
.about-contents-main .brand-box {
    margin-bottom: 80px;
}
.about-contents-main .brand-box.last {
    margin-bottom: 0;
}
.about-contents-main .brand-box .tit {
    margin-bottom: 25px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #343333;
    letter-spacing: -0.025em;
}
.about-contents-main .brand-box .img {
    text-align: center;
}
a.down-file {
    display: inline-block;
    width: 240px;
    height: 50px;
    margin-top: 90px;
    text-align: center;
    font-size: 15px;
    color: #fff;
    background: #4d4d4d;
    line-height: 50px;
    letter-spacing: -0.025em;
}
a.down-file:hover {
    text-decoration: none;
}

/* 2020-09-17 비즈콘 캐쉬안내 */
.visual-cash {
    margin-bottom: 69px;
}
.visual-cash figure {
    position: relative;
    z-index: 0;
}
.visual-cash figure::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 210px;
    background-color: #ff5757;
    position: absolute;
    left: 0;
    bottom: 0px;
    z-index: -1;
}
.visual-cash figure::before {
    content: "";
    display: block;
    position: absolute;
    left: 6%;
    bottom: 155px;
    width: 91px;
    height: 84px;
    background: url(/img/bizcon/bg-visual-cash-coin.png) no-repeat;
}

#contents-main.cash {
    width: 1280px;
}

.cash-contents {
    margin-bottom: 105px;
}
.cash-contents.last {
    margin-bottom: 0;
}
.cash-contents h1 {
    background: #2c2c2c;
    margin-bottom: 60px;
    padding: 22px 0;
    font-weight: 600;
    font-size: 26px;
    letter-spacing: -0.025em;
    color: #fff;
    text-align: center;
}
.cash-contents h1 > span {
    font-weight: 800;
}
.cash-contents-main figure.first {
    margin-top: -30px;
    margin-bottom: 25px;
}
.cash-contents-desc {
    margin-bottom: 60px;
    padding-left: 80px;
    font-size: 18px;
    color: #2f2f2f;
    line-height: 1.7em;
    letter-spacing: -0.025em;
}
.cash-contents-main > div:last-child {
    margin-bottom: 0;
}
.cash-contents-main .pos-al-right {
    padding-left: 300px;
    font-size: 16px;
}
.cash-contents-main a.btn-attention,
.cash-contents-main button.btn-attention {
    display: inline-block;
    width: auto;
}

.sub-contents-box a.btn {
    display: inline-block;
    width: 240px;
    height: 50px;
    margin-top: 90px;
    text-align: center;
    font-size: 15px;
    color: #fff;
    background: #4d4d4d;
    line-height: 50px;
    letter-spacing: -0.025em;
}
.sub-contents-box a.btn:hover {
    text-decoration: none;
}
.sub-contents-box .btn-red.movePage {
    margin-left: 20px;
    width: 232px;
    border: 1px solid #dc4a4a;
    border-radius: 5px;
    height: auto;
    padding: 14px 0 16px;
    line-height: 15px;
    box-shadow: 2px 2px 5px #e1e1e1;
}
.sub-contents-box .btn-red > span {
    position: relative;
    padding-right: 17px;
    background: url(/img/bizcon/ico_btn-red-arrow.png) no-repeat center right;
}
.sub-contents-box .btn-red.type02 {
    width: 400px;
    margin-top: 60px;
    box-shadow: none;
    padding: 32px 0;
    height: auto;
    line-height: 30px;
    font-size: 24px;
}
.sub-contents-box .btn-red.type02 > span {
    padding-right: 24px;
    background: url(/img/bizcon/ico_btn-red-arrow-type02.png) no-repeat center right;
}
.sub-contents-box .cash-contents-main .btn-red {
    margin-top: 0;
    margin-bottom: 95px;
}

/* 이용안내 nav */
#contents-nav-list.nav-use {
    background: #303032;
}
#contents-nav-list.nav-use .guide-nav-list li {
    width: 25%;
}
#contents-nav-list.nav-use .guide-nav-list li .nav-title {
    margin: 20px 0;
}
#contents-nav-list.nav-use .guide-nav-list li p + .nav-title {
    margin-top: 0;
}
#contents-nav-list.nav-use .guide-nav-list li + li:before {
    left: 0px;
    width: 1px;
    background: #59595b;
}
#contents-nav-list.nav-cash {
    background: #ff5757;
}
#contents-nav-list.nav-cash .guide-nav-list li {
    width: 20%;
}
#contents-nav-list.nav-cash .guide-nav-list li .nav-title {
    margin-bottom: 0;
    font-weight: 800;
    font-size: 24px;
    line-height: 35px;
}
#contents-nav-list.nav-cash .guide-nav-list li a:hover {
    text-decoration: none;
}
#contents-nav-list.nav-cash .guide-nav-list li + li:before {
    left: -2px;
    width: 4px;
    background: #b13d40;
}

.badge {
    position: absolute;
    left: 0;
    top: 0;
    width: 102px;
    line-height: 34px;
    height: 34px;
    font-size: 12px;
    color: #fff;
}
.badge.new {
    background: #ff5756;
}
.badge.existing {
    background: #576bff;
}

.guide-nav-list {
    width: 1280px;
    margin: 0 auto 77px;
}
.guide-nav-list li {
    position: relative;
    float: left;
    padding: 63px 0 71px;
    text-align: center;
}
.guide-nav-list li + li:before {
    position: absolute;
    top: 50%;
    height: 118px;
    transform: translateY(-50%);
    content: "";
}
.guide-nav-list li p {
    line-height: 18px;
    font-size: 12px;
    color: #fff;
}
.guide-nav-list li .nav-title {
    line-height: 50px;
    font-size: 22px;
    color: #fff;
}
a.btn-link,
button.btn-link {
    width: 120px;
    height: 31px;
    line-height: 31px;
    border: 1px solid #fff;
    font-size: 13px;
    color: #fff;
}
a.btn-link span,
button.btn-link span {
    position: relative;
    padding-right: 10px;
    background: url(/img/bizcon/ico_link-arrow.png) no-repeat center right;
}

/* 보안진단 2022추가 */
#closeAuthPopup {
    width: 20px;
    height: 20px;
    text-indent: -9999em;
    background: url(/img/bizcon/icon-pop-close.gif) no-repeat 0 0;
    position: absolute;
    top: 20px;
    right: 20px;
}

.bizcon-contents {
    font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
    letter-spacing: 0;
}
.bizcon-contents .txt-num {
    font-weight: 400;
    font-family: "Lato New";
}
.bizcon-contents .interestCount {
    font-family: "Lato New";
}
.bizcon-contents button,
.bizcon-contents a {
    font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
}

.bizcon-contents .contents-title-01 {
    overflow: hidden;
    position: relative;
    padding: 53px 0 20px 0;
}
.bizcon-contents .contents-title-01 h1 {
    display: inline-block;
    font-size: 28px;
    vertical-align: bottom;
}
.bizcon-contents .contents-title-01 .txt_sub {
    display: inline-block;
    font-size: 20px;
    vertical-align: bottom;
    margin: 0 0 3px 10px;
    color: #333;
}
.bizcon-contents .btn-all-download {
    position: absolute;
    top: 47px;
    right: 0;
    height: 38px;
    padding: 0 2px 0 8px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: normal;
    border: 1px solid #e6e6e6;
}
.bizcon-contents .btn-all-download:after {
    content: "";
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    margin: -2px 0 0 0;
    vertical-align: middle;
    background: url("/img/bizcon/ico_down_arrow.png") no-repeat;
    background-size: 24px 24px;
}

.bizcon-contents .sub-contents-box {
    position: relative;
    padding-bottom: 80px;
}
.bizcon-contents .sub-contents-box .board-check {
    position: absolute;
    top: -4px;
    right: 0;
}
.bizcon-contents .sub-contents-box .board-check .list {
    overflow: hidden;
}
.bizcon-contents .sub-contents-box .board-check .list li {
    float: left;
    position: relative;
    padding: 0 14px 0 14px;
}
.bizcon-contents .sub-contents-box .board-check .list li:before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 4px;
    border-left: 1px solid #e6e6e6;
    height: 14px;
}
.bizcon-contents .sub-contents-box .board-check .list li:last-child {
    padding-right: 0;
}
.bizcon-contents .sub-contents-box .board-check .list li:last-child:before {
    display: none;
}
.bizcon-contents .sub-contents-box .board-check .list li .check-box.type03 label {
    padding-left: 31px;
    line-height: 21px;
    color: #333;
}
.bizcon-contents .sub-contents-box .board-check .list li button {
    height: 22px;
    margin-top: -1px;
    color: #333;
}
.bizcon-contents .product {
    display: table;
}
.bizcon-contents .product a {
    display: table-cell;
}

.bizcon-contents .product img {
    float: none;
    display: inline-block;
    width: 90px;
    height: 90px;
    vertical-align: middle;
}
.bizcon-contents .product figcaption {
    float: none;
    display: table-cell;
    padding: 0 0 0 16px;
    margin: 0;
    vertical-align: middle;
}
.bizcon-contents .product figcaption a {
    display: block;
    font-size: 14px;
    font-weight: 400;
}
.bizcon-contents .cover-price,
.bizcon-contents .saving-price {
    font-family: "lato new";
    font-size: 18px;
    font-weight: 700;
}

.bizcon-contents .txt-list li {
    font-size: 14px;
    line-height: 1.57;
    color: #222;
}

.bizcon-contents .tb-bold-line thead th {
    border-top: 2px solid #494e55;
    border-bottom: 2px solid #494e55;
}
.bizcon-contents .tb-bold-line thead th .check-box.type03 {
    margin-top: 2px;
}
.bizcon-contents .tb-bold-line th {
    padding: 12px 12px 14px 12px;
}
.bizcon-contents .tb-bold-line td {
    padding: 16px 12px 16px 12px;
    text-align: center;
    border-bottom: 1px solid #e6e6e6;
    line-height: 1.2;
}
.bizcon-contents .tb-bold-line td.al-left {
    text-align: left;
}
.bizcon-contents .tb-bold-line tr.on td {
    background: #fcfcfc;
}
.bizcon-contents .tb-bold-line td.bd-right {
    border-right: 1px solid #e6e6e6;
}
.bizcon-contents .tb-bold-line td.bd-left {
    border-left: 1px solid #e6e6e6;
}
.bizcon-contents .tb-bold-line .link {
    border-bottom: 1px solid #222;
    line-height: 1.6;
}
.bizcon-contents .tb-bold-line .link:hover {
    text-decoration: none;
}

.bizcon-contents .best-interest-list li {
    float: left;
    width: 33.3334%;
    max-height: 200px;
    padding: 20px 0 0 0;
    border-left: none;
    box-sizing: border-box;
}
.bizcon-contents .best-interest-list li:first-child {
    border-left: 0 none;
}
.bizcon-contents .best-interest-list .product {
    padding: 20px 0;
}
.bizcon-contents .best-interest-list .product img {
    width: 158px;
    height: 158px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.bizcon-contents .best-interest-list .product .product-name {
    line-height: 1.57;
    margin-bottom: 11px;
}
.bizcon-contents .best-interest-list .product figcaption {
    padding-top: 3px;
    margin-left: 15px;
    line-height: 1;
}
.bizcon-contents .best-interest-list .product figcaption .brand {
    margin-bottom: 8px;
    color: #666;
    height: 14px;
}
.bizcon-contents .best-interest-list .product figcaption .saving-price {
    display: block;
    margin: 10px 0 29px 0;
}

.bizcon-contents .tb-default {
    background: #fff;
}
.bizcon-contents .tb-default caption span {
    display: block;
    font-size: 1.2857em;
    padding-bottom: 10px;
}
.bizcon-contents .tb-default tr:first-child th,
.bizcon-contents .tb-default tr:first-child td {
    border-top: 1px solid #b2b2b2;
}
.bizcon-contents .tb-default.type02 tr:first-child th,
.bizcon-contents .tb-default.type02 tr:first-child td {
    border-top: 0 none;
}
.bizcon-contents .tb-default th,
.bizcon-contents .tb-default td {
    font-size: 16px;
    text-align: left;
    border-bottom: 1px solid #e6e6e6;
}
.bizcon-contents .tb-default th {
    background: #f7f7f7;
    padding: 20px 20px 20px 30px;
}
.bizcon-contents .tb-default th.bg-red {
    background: #ff5757;
    color: #fff;
}
.bizcon-contents .tb-default td {
    padding: 5px 20px;
}
.bizcon-contents .tb-default .tb-txt {
    padding: 20px 0;
}

.bizcon-contents .btn-list li {
    float: left;
    margin-right: 10px;
}
.bizcon-contents .btn-list li button {
    font-size: 14px;
    font-weight: 400;
    color: #333;
}
.bizcon-contents .btn-list li button.on {
    font-weight: 700;
    color: #ff5757;
}
.bizcon-contents .btn-list.type02 li {
    float: left;
    width: 177px;
    padding: 0 20px 0 0;
    margin: 0 0 0 20px;
    line-height: 1.75;
}
.bizcon-contents .btn-list.type02 li button {
    overflow: hidden;
    height: 22px;
    max-width: 177px;
    text-align: left;
}
.bizcon-contents .btn-list.type03 {
    padding: 12px 0 2px 20px;
}

.bizcon-contents .check-list.type02 {
    padding: 8px 0 4px 20px;
}
.bizcon-contents .check-list.type02 li {
    float: left;
    margin: 7px 20px 8px 0;
}
.bizcon-contents .check-list.type02 li .check-box.type03 label {
    color: #333;
}

.bizcon-contents .shop-search-box {
    position: relative;
    width: 390px;
    height: 46px;
    margin: 15px 0 17px 20px;
}
.bizcon-contents .shop-search-box input[type="text"] {
    width: 330px;
    height: 44px;
    font-size: 16px;
    color: #222;
    padding-right: 48px;
    border-radius: 3px;
}
.bizcon-contents .shop-search-box input[type="text"]::placeholder {
    color: #8a8a8a;
}
.bizcon-contents .shop-search-box .btn-search {
    width: 36px;
    height: 36px;
    border: none;
    background: #fff url(/img/bizcon/icon-search_02.png) no-repeat center center;
    background-size: 36px 36px;
    text-indent: -9999em;
    position: absolute;
    right: 6px;
    top: 6px;
}
.bizcon-contents .shop-contents .total {
    font-size: 14px;
    font-family: "Lato New";
    font-weight: bold;
    letter-spacing: 0;
}
.bizcon-contents .shop-contents .total span {
    color: #ff5757;
}

.bizcon-contents .sort-type {
    position: absolute;
    right: 0;
    top: -3px;
}
.bizcon-contents .sort-type li {
    position: relative;
    float: left;
    padding: 0 14px 0 12px;
    border-left: none;
}
.bizcon-contents .sort-type li:before {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    right: 0;
    height: 14px;
    border-right: 1px solid #e6e6e6;
}
.bizcon-contents .sort-type li:first-child {
    border-left: 0 none;
}
.bizcon-contents .sort-type li:last-child {
    padding-right: 0;
}
.bizcon-contents .sort-type li:last-child:before {
    display: none;
}
.bizcon-contents .sort-type li button {
    color: #333;
}
.bizcon-contents .sort-type li.on button {
    color: #ff5757;
    font-weight: bold;
    border-bottom: none;
}
.bizcon-contents .sort-type li.on button:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: -4px 5px 0 0;
    vertical-align: middle;
    background: url("/img/bizcon/icon_select_check.png") no-repeat;
    background-size: 14px 14px;
}

.bizcon-contents .shop-contents-box {
    text-align: left;
}

.bizcon-contents .biz-product {
    display: inline-block;
    width: 1280px;
}
.bizcon-contents .biz-product li {
    float: left;
    width: 240px;
    margin-left: 20px;
    padding: 10px 0 52px 0;
}
.bizcon-contents .biz-product li:nth-child(5n + 1) {
    margin-left: 0;
}
.bizcon-contents .biz-product figure > a {
    display: block;
    height: 240px;
    text-align: center;
}
.bizcon-contents .biz-product figure img {
    width: 238px;
    height: 238px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.bizcon-contents .biz-product figcaption {
    margin-top: 19px;
    text-align: left;
}
.bizcon-contents .biz-product .brand,
.bizcon-contents .biz-product .product-name {
    display: block;
}
.bizcon-contents .biz-product .brand,
.bizcon-contents .biz-product .price {
    color: #222;
}
.bizcon-contents .biz-product .brand {
    color: #666;
    font-weight: bold;
}
.bizcon-contents .biz-product .rate {
    margin-right: 5px;
    color: #ff5757;
}
.bizcon-contents .biz-product .del {
    margin-left: 2px;
    color: #999;
    font-size: 14px;
    font-weight: normal;
}
.bizcon-contents .biz-product .product-name {
    font-weight: normal;
    padding-top: 8px;
    height: 47px;
    overflow: hidden;
    font-size: 1.1429em;
    line-height: 1.4;
    color: #222;
}
.bizcon-contents .biz-product .cover-price {
    display: block;
    border-top: none;
    margin-top: 8px;
    padding-top: 0px;
}
.bizcon-contents .biz-product .cover-price span {
    display: inline-block;
    vertical-align: middle;
}

.bizcon-contents .contents-title-03 {
    padding: 48px 0 23px 0;
}

.bizcon-contents .itemDelete {
    font-size: 13px;
}

.bizcon-contents .more-product-view {
    padding: 17px 0;
}
.bizcon-contents .more-product-view .currCnt {
    font-family: "Lato New";
}
.bizcon-contents .more-product-view .totCnt {
    font-family: "Lato New";
}

.bizcon-contents .tb-default.shop-sort-type th {
    color: #333;
}
.bizcon-contents .tb-default.shop-sort-type td {
    padding: 5px 0 5px 0;
}

.bizcon-contents #shop-category {
    background: url(/img/bizcon/bg-line-y.png) repeat-y 217px 0;
}

.company_circle_stamp {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
}
.company_circle_stamp img {
    display: block;
    width: 100%;
}

.company_square_stamp {
    display: inline-block;
    vertical-align: middle;
    width: 48px;
    height: 48px;
}
.company_square_stamp img {
    display: block;
    width: 100%;
}

//온라인 견적서
.pop-estimate {
    margin-top: 40px;

    .tbl_estimate {
        border-top: 2px solid $color-gray-01;
        border-bottom: 2px solid $color-gray-01;

        th,
        td {
            padding: 20px;
            border-top: 1px solid #e6e6e6;
            font-size: 14px;
            color: #222;
        }

        td {
            .list {
                margin-top: 10px;

                li {
                    line-height: 18px;
                    color: #222;

                    &::before {
                        content: "";
                        display: inline-block;
                        margin: 7px 6px 0 6px;
                        width: 3px;
                        height: 3px;
                        border-radius: 10px;
                        background-color: #222;
                        vertical-align: top;
                    }
                }
            }
        }

        tr:first-child {
            th,
            td {
                border-top: 0 none;
            }
        }
    }

    .notice {
        margin-top: 30px;

        .list {
            li {
                position: relative;
                margin-bottom: 6px;
                padding-left: 6px;
                font-size: 14px;
                line-height: 20px;
                color: $color-gray-04;

                &::before {
                    content: "";
                    position: absolute;
                    top: 9px;
                    left: 0;
                    width: 2px;
                    height: 2px;
                    background-color: #949494;
                }
            }
        }
    }

    .company_name_plate {
        font-weight: 400;
        font-size: 16px;
        color: $color-gray-01;
        line-height: 24px;
    }

    .pop-main-footer {
        padding-top: 40px;
    }
}

@charset "UTF-8";

// Font
$font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
$font-family-lato: "Lato New", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;

// Color - Brand & Secondary
$color-11st-red: #ff0038;
$color-11st-blue: #0b83e6;
$color-11st-yellow: #f8b907;
$color-11st-bluegray: #6d96c0;
$color-11st-green: #249356;
$color-luxury: #0f0fd9;
$color-11st-gradient-orange: #ff5a2e;
$color-11st-gradient-pink: #ff00ef;
$color-11st-gradient: linear-gradient(to right, $color-11st-gradient-orange, $color-11st-red 52%, $color-11st-gradient-pink);
$color-11st-gradient-vertical: linear-gradient($color-11st-gradient-orange, $color-11st-red 52%, $color-11st-gradient-pink);
$color-amazon-gradient: linear-gradient(to right, #3c99ea, #22cab0);

//Membership
$color-sktpass-01: #3617ce;

// Color - New Grayscale (2022.11)
$color-gray-01: #000;
$color-gray-02: #111;
$color-gray-03: #333;
$color-gray-04: #666;
$color-gray-05: #777;
$color-gray-06: #949494;
$color-gray-07: #999;
$color-gray-08: #bbb;
$color-gray-09: #ccc;
$color-gray-10: #ddd;
$color-gray-11: #eee;
$color-gray-12: #f4f4f4;
$color-gray-13: #fafafa;
$color-gray-14: #fff;

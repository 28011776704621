.side-bar {
    &.type_slide {
        top: 252px;
    }
}

.c-side-link {
    width: 88px;
    border: 1px solid #e1e1e1;
    background: $color-gray-14;

    &__item {
        color: #222;
        font-size: 0.857em;
        text-align: center;

        &:not(:first-child) {
            border-top: 1px solid #e1e1e1;
        }

        a {
            display: block;
            padding: 12px 0;
            color: inherit;
            font-size: inherit;

            &:before {
                content: "";
                display: block;
                width: 40px;
                height: 40px;
                margin: 0 auto 12px;
            }

            &:hover {
                text-decoration: none;
            }
        }

        &--faq {
            a {
                &:before {
                    @include sprite-retina($sp_main_2x_ico_skyscraper_faq);
                }
            }
        }

        &--guide {
            a {
                &:before {
                    @include sprite-retina($sp_main_2x_ico_skyscraper_guide);
                }
            }
        }

        &--list {
            a {
                &:before {
                    @include sprite-retina($sp_main_2x_ico_skyscraper_list);
                }
            }
        }
    }
}

.c-side-today {
    width: 88px;
    margin-top: 10px;
    padding: 12px 8px;
    border: 1px solid #e1e1e1;
    background: $color-gray-14;
    box-sizing: border-box;

    &__title {
        margin-left: -8px;
        margin-right: -8px;
        color: #222;
        font-size: 1em;
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
    }

    &__count {
        margin-top: 10px;
        color: #ff5888;
        font-family: $font-family-lato;
        font-size: 1.143em;
        text-align: center;
    }

    &__list {
        margin-top: 10px;
        overflow: hidden;

        &-inner {
            @include clearfix
        }
    }

    &__slide {
        float: left;
        width: 72px;
    }

    &__item {
        display: block;

        img {
            display: block;
            width: 100%;
        }
    }

    &__controls {
        margin-top: 10px;
        text-align: center;
    }

    &__control {
        width: 28px;
        height: 28px;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;

        &--prev {
            @include sprite-retina($sp_main_2x_ico_controls_arrow_left);

            &:disabled {
                @include sprite-retina($sp_main_2x_ico_controls_arrow_left_disabled);
            }
        }

        &--next {
            @include sprite-retina($sp_main_2x_ico_controls_arrow_right);

            &:disabled {
                @include sprite-retina($sp_main_2x_ico_controls_arrow_right_disabled);
            }
        }
    }
}

.c-side-top {
    margin-top: 20px;
    text-align: center;

    &__button {
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        @include sprite-retina($sp_main_2x_btn_wingtop);
    }
}



.s-main,
.s_main {
    padding-bottom: 100px;
    font-family: $font-family;

    .eng,
    .num {
        display: inline-block;
        font-family: $font-family-lato;
    }
}

[class^="b-main"] {
    .c-p-headline {
        margin-top: 0;
        padding-top: 50px;
    }

    &.b-main-bg {
        margin-top: 50px;
        padding: 60px 0;
        background: $color-gray-13;

        .c-p-headline {
            padding-top: 0;
        }
    }

    .b-main-inner {
        width: 1280px;
        margin: 0 auto;
    }
}

.b-main-themeshop {
    .c-main-list {
        &__item {
            $width-themeshop: 275px;

            flex-basis: $width-themeshop;
            width: $width-themeshop;
            margin-right: 0;
            margin-top: 0;

            &:not(:first-child) {
                margin-left: 60px;
            }

            &:nth-child(n + 4) {
                margin-top: 0;
            }
        }

        &__controls {
            position: absolute;
            top: -49px;
            right: 0;
        }

        &__control {
            color: transparent;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;

            &--prev {
                @include sprite-retina($sp_main_2x_ico_controls_arrow_left);

                &:disabled {
                    @include sprite-retina($sp_main_2x_ico_controls_arrow_left_disabled);
                }
            }

            &--next {
                @include sprite-retina($sp_main_2x_ico_controls_arrow_right);

                &:disabled {
                    @include sprite-retina($sp_main_2x_ico_controls_arrow_right_disabled);
                }
            }
        }
    }
}

.b-main-guide {
    .c-p-headline {
        margin-bottom: 16px;

        &__title {
            font-size: 2.286em;
        }
    }
}

.b-main-interest {
    .c-p-headline {
        &__title {
            font-size: 2em;
        }
    }

    .c-main-list {
        &__item {
            margin-right: 40px;
            box-sizing: border-box;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
}

.b-main-notice {
    .c-p-headline {
        &__title {
            font-size: 2em;
        }
    }
}

.c-main-promotion {
    display: flex;

    &__billborad {
        flex-basis: 860px;
    }

    &__banner {
        flex-basis: 419px;
        flex-grow: 1;
    }
}

.c-main-billborad {
    position: relative;
    overflow: hidden;

    &__item {
        position: relative;
        float: left;

        & > a {
            display: block;

            img {
                display: block;
                width: 100%;
                height: 480px;
            }
        }
    }

    &__controls {
        position: absolute;
        right: 30px;
        bottom: 30px;
    }

    &__activation {
        position: absolute;
        top: 0;
        left: -55px;
    }

    .bx-controls-auto {
        &-item {
            & > a {
                display: block;
                color: transparent;
                text-indent: 100%;
                white-space: nowrap;
                overflow: hidden;

                &.active {
                    display: none;
                }

                &.bx-start {
                    @include sprite-retina($sp_main_2x_btn_slide_play);
                }

                &.bx-stop {
                    @include sprite-retina($sp_main_2x_btn_slide_pause);
                }
            }
        }
    }

    &__auto {
        &-control {
            display: block;
            color: transparent;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;

            &.active {
                display: none;
            }

            &--start {
                @include sprite-retina($sp_main_2x_btn_slide_play);
            }

            &--stop {
                @include sprite-retina($sp_main_2x_btn_slide_pause);
            }
        }
    }

    &__prev,
    &__next {
        position: absolute;
        top: 0;

        .bx-prev,
        .bx-next,
        &-button {
            position: absolute;
            top: 0;
            width: 36px;
            height: 45px;
            color: transparent;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                top: 17px;
                width: 10px;
                height: 10px;
                border: 1px solid $color-gray-14;
                transform: rotate(-45deg);
            }
        }
    }

    &__prev {
        left: 0;

        .bx-prev,
        &-button {
            left: 0;

            &:before {
                left: 20px;
                border-right: 0;
                border-bottom: 0;
            }
        }
    }

    &__next {
        right: 0;

        .bx-next,
        &-button {
            right: 0;

            &:before {
                right: 20px;
                border-top: 0;
                border-left: 0;
            }
        }
    }

    &__pager {
        min-width: 130px;
        height: 45px;
        padding: 14px 36px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 22px;
        color: $color-gray-14;
        font-size: 1.214em;
        font-family: $font-family-lato;
        text-align: center;
        box-sizing: border-box;

        .current {
            color: $color-gray-14;
            font-weight: bold;
        }

        .total {
            opacity: 0.6;

            &:before {
                content: "/";
                display: inline-block;
                margin-right: 3px;
            }
        }
    }
}

.c-main-banner {
    height: 100%;
    border: 1px solid #e6e6e6;
    border-top: 0;
    box-sizing: border-box;

    &__list {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
    }

    &__item {
        width: 50%;
        height: 50%;
        box-sizing: border-box;

        &:nth-child(even) {
            border-left: 1px solid #e6e6e6;
        }

        &:nth-child(n + 3) {
            border-top: 1px solid #e6e6e6;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: 0;

        img {
            width: 100%;
        }
    }
}

.c-p-headline {
    margin: 50px 0 20px;

    &__title {
        color: $color-gray-02;
        font-size: 1.714em;
        font-weight: 800;
    }
}

.c-main-list {
    position: relative;

    &__pack {
        overflow: hidden;
    }

    &__product {
        display: flex;
        flex-wrap: wrap;

        &--nowrap {
            flex-wrap: nowrap;
        }
    }

    &__item {
        $width-basic: 400px;

        position: relative;
        display: flex;
        flex-shrink: 0;
        flex-basis: $width-basic;
        width: $width-basic;
        margin-right: 40px;

        &:nth-child(3n) {
            margin-right: 0;
        }

        &:nth-child(n + 4) {
            margin-top: 30px;
        }
    }
}

.c-main-guide {
    display: flex;

    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 50%;
        padding: 0 60px;
        box-sizing: border-box;

        &:first-child {
            padding-left: 0;
        }

        &:not(:first-child) {
            border-left: 1px solid $color-gray-11;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &__desc {
        margin-bottom: auto;
        color: #222;
        font-size: 1em;
        line-height: 1.571em;
    }

    &__view {
        margin-top: 26px;

        &-link {
            display: block;
            color: transparent;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;

            @include sprite-retina($sp_main_2x_btn_guide);

            &:hover {
                @include sprite-retina($sp_main_2x_btn_guide_hover);
            }
        }
    }
}

.c-main-themeshop {
    display: flex;

    .c_tab_themeshop {
        flex-shrink: 0;
        width: 275px;
        margin-right: 60px;
        border-top: 2px solid #494e55;

        li {
            float: none;
            border-bottom: 1px solid $color-gray-11;

            button {
                display: block;
                width: 100%;
                height: 55px;
                color: #222;
                font-size: 1.071em;
                font-weight: normal;
                text-align: left;

                &[aria-selected="true"] {
                    color: #ff5888;
                    font-weight: bold;
                }

                &:hover {
                    text-decoration: underline;
                }

                .in_btn {
                    display: block;
                    max-height: 45px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    .c_tab_content {
        display: none;
        width: 945px;

        &.active {
            display: block;
        }
    }
}

.c-main-notice {
    position: relative;

    &__item {
        display: flex;
        justify-content: space-between;
        color: #222;
        font-size: 1.214em;
        text-decoration: none;

        &:not(:first-child) {
            margin-top: 15px;
        }

        &.emphasis {
            .c-main-notice__subject {
                color: #ff5888;
            }
        }

        &.bold {
            .c-main-notice__subject {
                font-weight: 700;
            }
        }
    }

    &__subject {
        margin-right: 20px;
        padding-bottom: 1px;
        color: #222;
        font-size: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__date {
        color: $color-gray-04;
        font-size: inherit;
        font-family: $font-family-lato;
    }

    &__more {
        position: absolute;
        top: 0;
        right: 0;

        &-link {
            position: absolute;
            top: -50px;
            right: 0;
            width: 28px;
            height: 28px;
            color: transparent;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;

            &:link {
                color: transparent;
            }

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 2px;
                background: $color-gray-04;
            }

            &:after {
                transform: rotate(-90deg);
            }
        }
    }
}

.c-main-link {
    display: flex;
    margin-top: 50px;
    border: 1px solid #e1e1e1;

    &__item {
        position: relative;
        flex-grow: 1;
        color: $color-gray-02;
        font-size: 1.429em;
        font-weight: bold;
        text-align: center;

        &:not(:first-child) {
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 1px;
                height: 40px;
                margin-top: -20px;
                background: #e1e1e1;
            }
        }

        a {
            display: block;
            height: 80px;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: 80px;

            &:before {
                content: "";
                display: inline-block;
                margin: -3px 10px 0 0;
                vertical-align: middle;
            }
        }

        &--service {
            a {
                &:before {
                    @include sprite-retina($sp_main_2x_ico_link_service);
                }
            }
        }

        &--refund {
            a {
                &:before {
                    @include sprite-retina($sp_main_2x_ico_link_refund);
                }
            }
        }

        &--example {
            a {
                &:before {
                    @include sprite-retina($sp_main_2x_ico_link_example);
                }
            }
        }

        &--faq {
            a {
                &:before {
                    @include sprite-retina($sp_main_2x_ico_link_faq);
                }
            }
        }

        &--qna {
            a {
                &:before {
                    @include sprite-retina($sp_main_2x_ico_link_qna);
                }
            }
        }

        &--notice {
            a {
                &:before {
                    @include sprite-retina($sp_main_2x_ico_link_notice);
                }
            }
        }
    }
}

@charset "UTF-8";

html.cont_fix {
    overflow: hidden;
}

.c-modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;

    &.active {
        display: block;
    }

    &--dimmed {
        background: rgba(0, 0, 0, 0.4);
    }

    &__inner {
        position: relative;
        margin: 50vh auto;
        padding-bottom: 30px;
        transform: translateY(-50%);
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12);
    }

    &__header {
        padding: 20px 0;
        border-bottom: 1px solid #eee;
    }

    &__title {
        color: $color-gray-02;
        font-size: 1.357em;
        text-align: center;
    }

    &__content {
        padding: 30px 30px 0;
        color: $color-gray-03;
        line-height: 1.5;

        &.scroll {
            overflow-y: auto;
            height: 100px;
        }
    }

    &__footer {
        margin-top: 30px;
        text-align: center;

        button {
            display: inline-block;
            margin: 0 5px;
        }
    }

    &__close {
        button {
            position: absolute;
            top: 17px;
            right: 21px;
            @include ico-cancel-retina(36px, 3px, $color-gray-02, 0);
        }
    }

    // 레이어 유형 - 공지사항
    &--notice,
    // 레이어 유형 - 컨텐츠 노출 (약관 등)
    &--content-view {
        .c-modal__inner {
            width: 500px;
        }

        .c-modal__content {
            position: relative;
            max-height: 408px;
            overflow-y: auto;

            * + .notice_img {
                margin-top: 24px;
            }
        }
    }

    &--notice {
        .c-modal__content {
            font-size: 1.071em;
        }

        .c-modal__footer {
            display: flex;
            justify-content: space-between;
            padding: 0 30px;

            button {
                width: 215px;
                margin: 0;
            }
        }
    }

    &--content-view {
        .c-modal__footer {
            padding: 0 30px;

            button {
                width: auto;
                min-width: 150px;
            }
        }
    }

    // 레이어 유형 - 공지사항 이미지 유형
    &--imagepop {
        .c-modal__inner {
            width: 500px;
            padding-bottom: 0;
        }

        .c-modal__header {
            padding: 0;
            border: 0;
        }

        .c-modal__content {
            padding: 0;
        }

        .c-modal__footer {
            display: flex;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            border-top: 1px solid $color-gray-12;

            button {
                width: 100%;
                height: 55px;
                font-size: 1.143em;

                &.btn_close {
                    color: #ff5888;
                }
            }
        }
    }

    // 레이어 유형 - 정보 내역
    &--info {
        .c-modal__inner {
            width: 680px;
        }

        .c-modal__content {
            position: relative;
            max-height: 600px;
            font-size: 1.071em;
            overflow-y: auto;
        }
    }

    // 레이어 유형 - alert
    &--alert {
        .c-modal__inner {
            width: 500px;
            border-radius: 0;
        }

        .c-modal__header {
            border-bottom: 1px solid #e5e5e5;
        }

        .c-modal__title {
            font-size: 1.429em;
        }

        .c-modal__content {
            font-size: 1.071em;
            text-align: center;
        }

        .c-modal__footer {
            padding: 0 30px;

            button {
                width: 150px;
                margin: 0;
            }
        }
    }
}

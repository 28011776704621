// @import "../../common/helpers/variables";
// @import "../../common/mixins";
@import "../../../common/mixins";
@import "../../../common/helpers/variables";

@mixin c-button-reset() {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;
    border: 1px solid transparent;

    &[type="button"],
    &[type="submit"] {
        border: 1px solid transparent;
    }

    //속성
    &--bold {
        font-weight: bold;
    }

    &--text-left {
        text-align: left;
    }

    &--arrow {
        //화살표사이즈
        //@include c-button-arrow(6px);
    }

    &--arrow-right {
        //화살표사이즈,버튼우측공간,arrow 센터정렬유무(true,false)
        //@include c-button-arrow(6px, 16px, false);
    }

    &--radius {
        //버튼 모서리 굴림
        //@include c-button-radius(6px);
    }

    &:disabled,
    &--disabled {
        cursor: pointer;
    }

    &:hover {
        text-decoration: none;
    }
}

@mixin c-button-set($fontSize, $btnPadding, $minWidth, $height, $width: auto, $radiusSize: 0) {
    width: $width;
    height: $height;
    padding: $btnPadding;
    min-width: $minWidth;
    font-size: $fontSize;
    line-height: calc(#{$height} - 2px);
    border-radius: $radiusSize;
}

@mixin c-button-arrow($size, $gap: 0, $center: true) {
    @if $center {
        &::after {
            width: $size;
            height: $size;
            margin-top: -#{round($size/2)};
            border-size: 1px;
            border-style: solid;
            display: inline-block;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg);
            vertical-align: middle;
            content: "";
        }
    } @else {
        padding-right: $gap;

        &::after {
            right: calc(#{$gap} - #{round($size/2 * -1)});
            width: $size;
            height: $size;
            border-size: 1px;
            border-style: solid;
            display: block;
            position: absolute;
            top: 50%;
            right: 10px;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg) translateX(-50%);
            content: "";
        }
    }
}

@mixin c-button-radius($size: 0) {
    border-radius: $size;
}

@mixin c-button-theme($theme) {
    @each $map2, $value2 in $button-theme {
        @each $map, $value in $value2 {
            @if $map2 == $theme {
                &--#{$map} {
                    &[type="button"],
                    &[type="submit"] {
                        color: map-get($value, font-color);
                        background-color: map-get($value, bg-color);
                        border-color: map-get($value, border-color);
                    }

                    color: map-get($value, font-color);
                    background-color: map-get($value, bg-color);
                    border-color: map-get($value, border-color);
                }
                &--#{$map}::after {
                    &[type="button"],
                    &[type="submit"] {
                        border-color: map-get($value, arrow-color);
                    }

                    border-color: map-get($value, arrow-color);
                }

                &--#{$map}:disabled,
                &--#{$map}.#{$theme}--disabled {
                    &[type="button"],
                    &[type="submit"] {
                        border-color: rgba(204, 204, 204, 0.2);
                        background: #fafafa;
                        color: #ccc;
                    }

                    border-color: rgba(204, 204, 204, 0.2);
                    background: #fafafa;
                    color: #ccc;
                }
            }
        }
    }
}
//버튼 테마 변수 셋팅
$button-theme: (
    c-button: (
        primary: (
            // font-color: $color-grayscale-13,
            font-color: $color-gray-14,
            bg-color: $color-11st-red,
            border-color: $color-11st-red,
            // arrow-color: $color-grayscale-13,
            arrow-color: $color-gray-14,
        ),
        alert: (
            font-color: $color-11st-red,
            // bg-color: $color-grayscale-13,
            bg-color: $color-gray-14,
            border-color: $color-11st-red,
            arrow-color: $color-11st-red,
        ),
        normal: (
            // font-color: $color-grayscale-02,
            font-color: $color-gray-02,
            // bg-color: $color-grayscale-13,
            bg-color: $color-gray-14,
            // border-color: $color-grayscale-07,
            border-color: $color-gray-09,
            // arrow-color: $color-grayscale-07,
            arrow-color: $color-gray-09,
        ),
        secondary: (
            font-color: $color-11st-blue,
            // bg-color: $color-grayscale-13,
            bg-color: $color-gray-14,
            border-color: $color-11st-blue,
            arrow-color: $color-11st-blue,
        ),
        black: (
            // font-color: $color-grayscale-13,
            font-color: $color-gray-14,
            // bg-color: $color-grayscale-03,
            bg-color: $color-gray-03,
            // border-color: $color-grayscale-03,
            border-color: $color-gray-03,
            // arrow-color: $color-grayscale-13,
            arrow-color: $color-gray-14,
        ),
    ),
    c-button-luxy: (
        primary: (
            // font-color: $color-grayscale-13,
            font-color: $color-gray-14,
            bg-color: $color-luxury,
            border-color: $color-luxury,
            arrow-color: $color-luxury,
        ),
    ),
);

.c-button {
    @include c-button-reset();
    @include c-button-theme("c-button");

    //font-size, padding, min-width, height, width, border-radius
    //@include c-button-set(48px, 0 2px, 44px, 17px, 150px, 0px);

    //font-size, padding, min-width, height, width 인자 없을시 auto, border-radius
    //@include c-button-set(48px, 0 2px, 44px, 17px, auto, 4px);
}

//서비스별 예시
.c-button-luxy {
    @include c-button-reset();
    @include c-button-theme("c-button-luxy");

    //font-size, padding, min-width, height, width
    //@include c-button-set(48px, 0 2px, 44px, 17px, 150px, 0px);

    //font-size, padding, min-width, height, width 인자 없을시 auto
    //@include c-button-set(48px, 0 2px, 44px, 17px, auto, 4px);
}
